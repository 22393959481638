import PageMeta from "components/components/Meta/PageMeta";
import TotalLoadActualAndDayAheadChartContainer from "components/dataVisualization/TotalLoadActualAndDayAhead/TotalLoadActualAndDayAheadChartContainer";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";

const TotalLoadActualAndDayAhead: React.FC = () => (
  <>
    <PageMeta title="Total Load: Actual and Day-Ahead forecast" />
    <PageHeader
      title="Total Load: actual and day-ahead forecast"
      pageDescription={
        <>
          <p>
            Actual total load per bidding zone per Settlement Period, the total
            load being defined as equal to the sum of power generated by plants
            on both Transmission System Operators/Distribution System Operators
            networks, from which is deduced:
            <ul>
              <li>
                The balance (export-import) of exchanges on interconnections
                between neighbouring bidding zones.
              </li>
              <li>The power absorbed by energy storage resources.</li>
            </ul>
          </p>
          <p>
            Transmission System Operators shall calculate and submit a day-ahead
            forecast of the total load per zone.
          </p>
        </>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            For their control areas, Transmission System Operators shall
            calculate and submit the total load per Settlement Period to the
            ENTSO for Electricity for each bidding zone. It shall be published
            no later than one hour after the operating period. The information
            shall be published at the latest H+1 after the end of the operating
            period.
          </p>
          <p>
            A day-ahead forecast of the total load per Settlement Period per
            bidding zone at the latest two hours before the gate closure time of
            the day-ahead market in the bidding zone or at D-1, 12:00 in local
            time zone of the bidding zone at the latest when gate closure time
            does not apply. The day-ahead forecast has to be updated, if there
            are major changes. A major change represents a change of at least
            10% of the total load forecast in one Settlement Period. The primary
            owners of the data are Transmission System Operators and
            Distribution System Operators; The forecast of load:
            <ul>
              <li>is given for information purposes only.</li>
              <li>
                is drawn up, among other things, on the basis of meteorological
                data ahead of time. It is therefore likely to change in shape
                and level.
              </li>
            </ul>
          </p>
          <p>
            Note: The day-ahead forecast is calculated (estimated) on the
            historic load profile on similar days, taking into account the
            variables that affect electricity demand, such as weather
            conditions, climate and socioeconomic factors.
          </p>
        </>
      }
    />
    <FullWidthChartSection>
      <TotalLoadActualAndDayAheadChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="demand/actual/total" />
  </>
);

export default TotalLoadActualAndDayAhead;
