import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import {
  StyledInput,
  IconWithMargin,
  RowHeader,
  TitleRow,
} from "components/components/balancingMechanism/bmuView/BmuFuzzySearchBar/style";
import React, { ReactNode } from "react";
import {
  MenuListProps,
  components,
  FormatOptionLabelMeta,
  InputProps,
  ValueContainerProps,
} from "react-select";
import colours from "styles/colours";
import { BmuOption, IsMulti } from "utils/fuzzyBmuDataHelpers";

const SearchIcon: React.FC<{ colour: string }> = ({ colour }) => (
  <IconWithMargin aria-hidden>
    <Icon colour={colour} iconName={faSearch} />
  </IconWithMargin>
);

export const placeholder = (
  <span id="bmu-search-placeholder" data-test-id="bmu-search-placeholder">
    Search by BMU ID, lead party or NGC BMU name
  </span>
);

export const formatOptionLabel = (
  { nationalGridBmuName, elexonBmuId, bmuType, leadPartyName }: BmuOption,
  { context }: FormatOptionLabelMeta<BmuOption>
): ReactNode => {
  switch (context) {
    case "menu": // to be rendered inside open dropdown menu
      return (
        <div className="columns bmu-result-row" data-test-id="bmu-option-row">
          <div className="column is-4">{leadPartyName}</div>
          <div className="column is-3">{elexonBmuId}</div>
          <div className="column is-3">{nationalGridBmuName}</div>
          <div className="column is-2">{bmuType}</div>
        </div>
      );
    case "value": // to be rendered inside react-select when selected and dropdown is closed
      // All the details of the selected BMU are displayed on the page,
      // but placeholder text is shown in the search bar
      return placeholder;
    default:
      throw new Error(
        `Unsupported context "${context}" for ${formatOptionLabel.name} function`
      );
  }
};

export const MenuList: React.FC<MenuListProps<BmuOption, IsMulti>> = (
  props
) => {
  const { children, ...rest } = props;
  return (
    <div data-test-id="bmu-menu-list">
      <TitleRow className="columns title-row">
        <RowHeader className="column is-4">Lead party name</RowHeader>
        <RowHeader className="column is-3">BMU ID</RowHeader>
        <RowHeader className="column is-3">NGC BMU name</RowHeader>
        <RowHeader className="column is-2">BMU type</RowHeader>
      </TitleRow>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.MenuList {...rest} className="menu-list">
        {children}
      </components.MenuList>
    </div>
  );
};

export const Input: React.FC<InputProps<BmuOption, IsMulti>> = (props) => (
  <StyledInput>
    <components.Input
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      data-test-id="bmu-search-input"
      aria-labelledby="bmu-search-placeholder"
      maxLength={100}
    />
  </StyledInput>
);

export const LoadingIndicator: React.FC = () => <LoadingSpinner isLoading />;

export const ValueContainer: React.FC<
  ValueContainerProps<BmuOption, IsMulti>
> = (props) => {
  const { children, selectProps } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ValueContainer {...props}>
      <SearchIcon
        colour={selectProps.value ? colours.darkGrey : colours.elexonBlue}
      />
      {children}
    </components.ValueContainer>
  );
};
