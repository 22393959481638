import { parseStringToBoolean } from "utils/booleanUtils";

/* Object with properties set to true when they should be enabled */
const featureFlags = {
  accountArea: parseStringToBoolean(process.env.REACT_APP_ENABLE_ACCOUNT_AREA),

  google_analytics: parseStringToBoolean(
    process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS
  ),
  hotjar_analytics: parseStringToBoolean(
    process.env.REACT_APP_ENABLE_HOTJAR_ANALYTICS
  ),
  cookieBanner: parseStringToBoolean(
    process.env.REACT_APP_ENABLE_COOKIE_BANNER
  ),
  react18Render: process.env.REACT_APP_ENV_PREFIX === "dev",
};

export default featureFlags;
