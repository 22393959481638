import PageMeta from "components/components/Meta/PageMeta";
import DemandForecastChartContainer from "components/dataVisualization/DemandForecast/DemandForecastChartContainer/DemandForecastChartContainer";
import DemandForecastSummaryTile from "components/dataVisualization/DemandForecast/DemandForecastSummaryTile/DemandForecastSummaryTile";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import React from "react";

const DemandForecast: React.FC = () => (
  <>
    <PageMeta title="Demand forecast" />
    <PageHeader
      title="Demand forecast"
      pageDescription={
        <p>
          National Grid ESO (NGESO) provides demand forecast data as National
          Demand Forecast and Transmission System Demand Forecast in three
          different timeframe and resolution timescales as day and day ahead
          (reported for each Settlement Period), 2 to 14 days ahead (reported
          daily) and 2 to 52 weeks ahead (reported weekly). Additionally the
          Demand Outturn, i.e. the historical demand, is also included on this
          chart.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            The Initial National Demand Outturn (INDO) and Initial Transmission
            System Demand Outturn (ITSDO) values are also included to provide a
            direct representation of forecast vs actual outturn. You can turn
            these on by clicking &quot;Filter&quot; in the top left corner of
            the graph.
          </p>
          <p>
            The Day Ahead Demand Forecast page provides a forward view of demand
            as NDF and TSDF based on operational metering data. The same
            calculation logic for INDO applies to NDF with reference to which
            variable are taken into consideration in the aggregation (e.g.
            interconnector demand), making the data comparable; as does ITSDO to
            TSDF.
          </p>
          <p>
            For this page, only TSDF data is reported both at National or
            Boundary Zones, while other data (NDF, INDO and ITSDO) is only
            reported at National level. To learn more about zones and boundaries
            visit the&nbsp;
            <a
              href="https://www.nationalgrideso.com/research-publications/etys/electricity-transmission-network-requirements"
              target="_blank"
              rel="noreferrer"
            >
              NGESO website
            </a>
            . Additionally, a resource for mapping BM Units to zones can be
            downloaded{" "}
            <a href="/bm_unit_to_oc2_zone_mapping.xls">from this location</a>.
          </p>
        </>
      }
    >
      <DemandForecastSummaryTile />
    </PageHeader>
    <FullWidthChartSection>
      <DemandForecastChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="forecast/demand/day-ahead" />
  </>
);

export default DemandForecast;
