import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import LolpdrmChartContainer from "components/dataVisualization/lolpdrm/LolpdrmChartContainer/LolpdrmChartContainer";
import React from "react";

const Lolpdrm: React.FC = () => (
  <>
    <PageMeta title="Loss of Load Probability (LoLP) and De-rated Margin" />
    <PageHeader
      title="Loss of Load Probability (LoLP) and De-rated Margin"
      pageDescription={
        <>
          <p>
            The LoLP is a measure of system reliability calculated by the System
            Operator (SO) for each Settlement Period. The LoLP indicates the
            probability of insufficient capacity to meet the generation needs.
          </p>
          <p>
            NGESO shall calculate the indicative Loss of Load Probability values
            in accordance with the Dynamic LoLP Function Methodology.
          </p>
          <p>
            The Balancing Mechanism Reporting Agent (BMRA) and Settlement
            Administration Agent (SAA) use Final LoLP values to produce Reserve
            Scarcity Prices.
          </p>
        </>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            This page displays two graphs showing the latest forecast received.
            The tooltip will provide additional information for all forecasts
            received for the Settlement Period being viewed.
          </p>
          <p>There are two types of LoLP values - indicative and final.</p>
          <p>
            Indicative - At 1200 hrs each calendar day data will be received
            containing values with the current and following Operational day.
            Then at 8 hours, 4 hours and 2 hours prior to the beginning of that
            Settlement Period for each Settlement Period during each Settlement
            Day. The data can be used as an indication of the level of scarcity
            anticipated ahead of Gate Closure for a Settlement Period. Finally 1
            hour before the beginning of a Settlement Period a value will be
            received for that Settlement Period.
          </p>
          <p>
            Default view of the graph is the 24hr forecast. The 1200 hr data
            file received can typically provide values until 03:30 on Day+2
            which provides a greater indicative forecast.
          </p>
          <p>
            Final - Loss of Load Probability value is derived from data
            available to NGESO at Gate Closure. This is the best indication of
            expected scarcity during a Settlement Period.
          </p>
        </>
      }
    />
    <FullWidthChartSection>
      <LolpdrmChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="datasets/LOLPDRM" />
  </>
);
export default Lolpdrm;
