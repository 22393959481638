import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import IndicatedForecastsChartContainer from "components/dataVisualization/indicatedForecasts/IndicatedForecastsChartContainer/IndicatedForecastsChartContainer";
import React from "react";

const IndicatedForecasts: React.FC = () => (
  <>
    <PageMeta title="Indicated forecasts" />
    <PageHeader
      title="Indicated forecasts"
      pageDescription={
        <>
          <p>
            National Grid ESO provides a forward view of several auxiliary data
            to provide additional context to generation and demand data; these
            are currently reported as Indicated Margin (MELNGC), Indicated
            Imbalance (IMBALNGC), Indicated Generation (INDGEN), and Indicated
            Demand (INDDEM) for current day and forecast up to a day ahead.
          </p>
          <p>
            The National Demand Forecast (NDF) is also presented here as it is a
            related dataset.
          </p>
        </>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            Market participants, primarily generation units, submit to NGESO a
            forward view of their maximum capacity values where they will be
            generating over a period of time i.e. a MW profile over time; this
            is referred to as the Maximum Export Limit (MEL). The participants
            will also submit Physical Notification (PNs) over that same period;
            which represents what the participants expect to be exporting or
            importing over a period of time.
          </p>
          <p>
            <b>INDGEN:</b> Indicated Generation (INDGEN) is the output from the
            aggregation of the PNs for all units which will be exporting energy
            for the forecast period.
          </p>
          <p>
            <b>INDDEM:</b> The Indicated Demand (INDDEM) is the sum of all the
            PNs for units which are forecasted to be importing energy.
          </p>
          <p>
            <b>IMBALNGC:</b> Indicated Imbalance (IMBALNGC) is the output from
            the difference of INDGEN and NDF; the closer to zero means the more
            balanced the system will be, for that forecasted period.
          </p>
          <p>
            <b>MELNGC:</b> NGESO aggregates the MEL for all participants and
            subtracts this from the National Demand Forecast (NDF) and reports
            this as the Indicated Margin (MELNGC); the higher the MELNGC, the
            greater spare capacity for that forecasted period on the system.
          </p>
        </>
      }
    />
    <FullWidthChartSection>
      <IndicatedForecastsChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="forecast/indicated/day-ahead" />
  </>
);

export default IndicatedForecasts;
