import TabModal, {
  ModalProps,
} from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/TabModal";
import React from "react";

import { DescriptionSections } from "./style";

const SystemWarningsModal: React.FC<ModalProps> = ({ isOpen, close }) => (
  <TabModal
    title="System Warnings"
    headingId="system-warnings-modal-header"
    isOpen={isOpen}
    close={close}
  >
    <DescriptionSections>
      <>
        <p>
          System Warnings are messages published by the System Operator to
          inform all involved parties of important information concerning
          operational and commercial issues. For example, System Warnings are
          used to notify Inadequate System Margins, Demand Control Warnings, IT
          System Outages or Failures, Risk of System Disturbance and Manifest
          Error Claims. System Warnings are published at the System Operators
          discretion.
        </p>
        <p>
          The System Warnings facility is additionally utilised by the System
          Operator to provide notification of amended Acceptance Data relating
          to Emergency Instructions (Emergency Acceptance Data).
        </p>
        <p>
          For further information on System Warnings/Notices and why NGESO issue
          them please visit &nbsp;
          <a
            href="https://www.nationalgrideso.com/electricity-explained/how-do-we-balance-grid/what-are-system-notices"
            target="_blank"
            rel="noreferrer"
          >
            &quot;what are system notices?&quot;
          </a>
          &nbsp; of NGESO website
        </p>
      </>
      <>
        <div>
          Two notices will be issued in respect of each Emergency Instruction:
          <ol>
            <li>
              As soon as possible after an Emergency Instruction has been issued
              issued to the relevant Party, the System Warnings facility will
              publish details of the affected BM Unit with the relevant
              Settlement Day(s) and Settlement Period(s)
            </li>
            <li>
              Where possible by the SAA II Run for the relevant Settlement
              Day(s), the System Warnings facility will publish details of the
              affected BM Unit, the relevant Settlement Day(s) and Settlement
              Period(s), plus the Emergency Acceptance Data and a statement
              whether it will be treated as SO-flagged or not, and a rationale
              for that decision. The BOAL will not appear on the Physical Data
              page of the BMRA or be reflected in the BMRA System Price
              calculations.
            </li>
          </ol>
        </div>
        <br />
        <p>
          Note that System Warnings relating to System Operator to System
          Operator Trades are only displayed on the SO-SO Prices page.
        </p>
      </>
    </DescriptionSections>
  </TabModal>
);

export default SystemWarningsModal;
