import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import GenerationForecastChartContainer from "components/dataVisualization/generationForecast/GenerationForecastChartContainer/GenerationForecastChartContainer";
import React from "react";

const GenerationForecast: React.FC = () => (
  <>
    <PageMeta title="Generation forecast" />
    <PageHeader
      title="Generation forecast"
      pageDescription={
        <p>
          National Grid ESO creates a forward view of availability (also
          referred to as Output Usable data under the Grid Code) for generation
          and interconnector capacity, accounting for planned outages.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            This data is derived by National Grid ESO using REMIT Data published
            from the BMRS. The data provides three levels of aggregation:
            National Output Usable (NOU), Fuel Output Usable (FOU) and Balancing
            Mechanism Unit Output Usable (UOU). This data is also represented in
            three time resolutions; daily in the 2 to 14 days ahead forward
            availability, weekly in the 2 to 52 weeks forward availability and
            weekly in the 2 to 156 weeks forward availability.
          </p>
          <p>
            For all aggregation and resolution types, the data is updated once
            daily.
          </p>
        </>
      }
    />
    <FullWidthChartSection>
      <GenerationForecastChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="datasets/FOU2T14D" />
  </>
);

export default GenerationForecast;
