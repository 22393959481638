import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import InterconnectorFlowsChartContainer from "components/dataVisualization/interconnectorFlows/InterconnectorFlowsChartContainer/InterconnectorFlowsChartContainer";
import React from "react";

const InterconnectorFlows: React.FC = () => (
  <>
    <PageMeta title="Interconnector flows" />
    <PageHeader
      title="Interconnector flows"
      pageDescription={
        <p>
          The Interconnector flows report is derived from the Generation by Fuel
          Type data and shows both interconnector imports and exports; the data
          is updated every five minutes.
        </p>
      }
    />
    <FullWidthChartSection>
      <InterconnectorFlowsChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="generation/outturn/summary" />
  </>
);

export default InterconnectorFlows;
