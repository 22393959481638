import DateSelector, {
  ReadOnlyDates,
} from "components/components/DateSelector/DateSelector";
import {
  BmuDataContainer,
  BmuFieldValue,
  DateContainer,
  DetailsAndDateContainer,
  FieldName,
  FieldValue,
  NameValuePair,
  NoBmuSelected,
  OtherDataContainer,
} from "components/components/balancingMechanism/bmuView/BmuDetails/style";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React from "react";
import { ONE_DAY } from "utils/dateHelpers";
import { BmuOption } from "utils/fuzzyBmuDataHelpers";

interface BmuDataProps {
  bmu: BmuOption;
}

const BmuData: React.FC<BmuDataProps> = ({
  bmu: { elexonBmuId, leadPartyName, nationalGridBmuName, bmuType },
}) => (
  <BmuDataContainer>
    <NameValuePair>
      <FieldName>BMU ID</FieldName>
      <BmuFieldValue>{elexonBmuId || "—"}</BmuFieldValue>
    </NameValuePair>
    <OtherDataContainer>
      <NameValuePair>
        <FieldName>Lead party name</FieldName>
        <FieldValue>{leadPartyName || "—"}</FieldValue>
      </NameValuePair>
      <NameValuePair>
        <FieldName>NGC BMU Name</FieldName>
        <FieldValue>{nationalGridBmuName || "—"}</FieldValue>
      </NameValuePair>
      <NameValuePair>
        <FieldName>BMU type</FieldName>
        <FieldValue>{bmuType || "—"}</FieldValue>
      </NameValuePair>
    </OtherDataContainer>
  </BmuDataContainer>
);

interface Props {
  selectedBmu: BmuOption | null;
  dateFilter: DateFilterModel | null;
  handleChangeToDateSelection: (newDateFilter: DateFilterModel) => void;
}

const BmuDetails: React.FC<Props> = ({
  selectedBmu,
  dateFilter,
  handleChangeToDateSelection,
}) => (
  <DetailsAndDateContainer aria-live="polite">
    {selectedBmu ? (
      <BmuData bmu={selectedBmu} />
    ) : (
      <NoBmuSelected>
        No BMU selected. Use the search bar above to find a BMU to view.
      </NoBmuSelected>
    )}
    {dateFilter !== null && (
      <DateContainer data-test-id="bmu-date-container">
        <DateSelector
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateTabs={[DateSelectorTabs.Date]}
          activeTab={DateSelectorTabs.Date}
          maxRange={ONE_DAY}
          readOnlyDates={selectedBmu ? ReadOnlyDates.None : ReadOnlyDates.Both}
        />
      </DateContainer>
    )}
  </DetailsAndDateContainer>
);

export default BmuDetails;
