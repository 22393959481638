import styled from "styled-components";
import colours from "styles/colours";

export const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const Subtitle = styled.p`
  font-size: 2em;
`;

export const LastUpdatedContainer = styled.div`
  color: ${colours.darkGrey};
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const SummaryTable = styled.table`
  thead > tr > td,
  tbody > tr > td {
    text-align: center;
  }

  thead > tr > td {
    background-color: ${colours.elexonBlue};
    border: 1px solid ${colours.white};
    color: white;
    font-weight: bold;
    vertical-align: middle;
  }

  tbody > tr:nth-child(even) {
    background-color: ${colours.lightGrey};
  }

  thead > tr:first-child > td:first-child,
  tbody > tr > td:first-child {
    font-weight: bold;
    text-align: left;
  }
`;

export const SectionRow = styled.tr`
  && {
    && {
      background-color: ${colours.elexonBlue}33;
      font-weight: bold;
    }
  }
`;

export const SubtotalsRow = styled.tr`
  && {
    && {
      background-color: ${colours.elexonBlack}80;
      color: ${colours.white};
    }
  }
`;

export const TotalsRow = styled.tr`
  && {
    && {
      background-color: ${colours.elexonBlack}B0;
      color: ${colours.white};
      font-weight: bold;
    }
  }
`;

export const SeparatorRow = styled.tr`
  && {
    background-color: ${colours.white};
    font-size: 4px;
  }
`;
