import PageMeta from "components/components/Meta/PageMeta";
import WindGenerationChartContainer from "components/dataVisualization/WindGeneration/WindGenerationChartContainer/WindGenerationChartContainer";
import WindGenerationSummaryTile from "components/dataVisualization/WindGeneration/WindGenerationSummaryTile/WindGenerationSummaryTile";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import React from "react";

const WindGeneration: React.FC = () => (
  <>
    <PageMeta title="Wind generation" />
    <PageHeader
      title="Wind generation"
      pageDescription={
        <>
          <p>
            National Grid ESO uses its wind power forecasting tool to produce
            hourly forecast for period from 20:00 (GMT) on the current day (D)
            to 20:00 (GMT) (D+2).
          </p>
          <p>
            To provide a visual summary of the relationship between outturn and
            forecast data, this graph is restricted to a four-day view. Please
            use the export button or the API to access this data in periods of
            up to two months.
          </p>
        </>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            NGESO forecast modelling is based on both historical outturn as well
            as local wind forecasts. This will provide wind generation forecast
            for wind farms which are visible to the ESO and have operational
            metering. This graph shows the actual outturn, derived from the
            Generation by Fuel Type data, to show a direct comparison between
            wind generation forecasts and out-turn. The Forecast value
            represents a single MW figure across all{" "}
            <a href="https://www.elexon.co.uk/documents/data/operational-data/power-park-modules/">
              Power Park Modules available from this location
            </a>
            .
          </p>
          <p>
            NGESO will publish updates to the forecasts values up to 8 times a
            day at 03:30, 05:30, 08:30, 10:30, 12:30, 16:30, 19:30 and 23:30.
            For visualisation purposes, only the earliest and latest forecasts
            values are presented on the website and the evolution of the
            forecast for each time is available via the APIs.
          </p>
          <p>
            In reference to the Out-turn Forecast Value, the MW totals represent
            an estimate of telemetered Wind Farm generation on the GB
            Transmission Network. Where available, real time telemetry is used,
            where it is not estimates of outturns are used.
          </p>
          <p />
        </>
      }
    >
      <WindGenerationSummaryTile />
    </PageHeader>
    <FullWidthChartSection>
      <WindGenerationChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="forecast/generation/wind/latest" />
  </>
);

export default WindGeneration;
