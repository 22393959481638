import { getRollingSystemDemandDownloadData } from "api/dataVisualisation/rollingSystemDemand/rollingSystemDemand";
import {
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import RollingSystemDemandChart from "components/dataVisualization/rollingSystemDemand/RollingSystemDemandChart/RollingSystemDemandChart";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useEffect, useMemo, useState } from "react";
import { transformRollingSystemDemandModelData } from "utils/ChartDataUtils/rollingSystemDemand/rollingSystemDemand";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import { addHoursToDate, ceilToHalfHour } from "utils/dateHelpers";

import useRollingSystemDemandChartContainerData from "./useRollingSystemDemandChartContainerData";

const RollingSystemDemandChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());
  const dateSelectorTabs = [
    DateSelectorTabs.OneHour,
    DateSelectorTabs.TwentyFourHours,
    DateSelectorTabs.OneWeek,
    DateSelectorTabs.OneMonth,
    DateSelectorTabs.OneYear,
    DateSelectorTabs.All,
  ];
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addHoursToDate(defaultEndDate, -1), defaultEndDate)
  );
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.OneHour
  );
  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useRollingSystemDemandChartContainerData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const chartData = useMemo(
    () => (rawData ? transformRollingSystemDemandModelData(rawData) : []),
    [rawData]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getRollingSystemDemandDownloadData(dateFilter, fileFormat),
      `RollingSystemDemand-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(newDateFilter);
    setActiveDateSelectorTab(newActiveTab);
  };

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
        />
      </ChartHeader>
      <RollingSystemDemandChart
        series={chartData}
        dataFetchStatus={status}
        rerequestData={fetchData}
      />
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
        />
      </ChartFooter>
    </>
  );
};

export default RollingSystemDemandChartContainer;
