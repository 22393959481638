import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import TextLink from "components/components/TextLink/TextLink";
import React from "react";
import routeConstants from "utils/routeConstants";

import { AdditionalInfo, StyledInfo, StyledTitle } from "./style";

interface Props {
  documentationLinkSlug: string;
  isFullWidth?: boolean;
  source?: ExternalDataSource;
}

export enum ExternalDataSource {
  NGESO = "National Grid ESO",
  Elexon = "Elexon",
}

const AdditionalInfoSection: React.FC<Props> = ({
  documentationLinkSlug,
  isFullWidth = false,
  source = ExternalDataSource.NGESO,
}: Props) => {
  const getDocumentationLink = (linkSlug: string): string =>
    `${routeConstants.apiDocs}/endpoint/${linkSlug}`;

  return (
    <AdditionalInfo>
      <FullWidthSection
        className={`is-grey-section ${isFullWidth && "expand-grey-section"}`}
      >
        <div className="container p-6">
          <div className="columns">
            <div className="column">
              <StyledTitle>Additional information</StyledTitle>
              <StyledInfo>Source: {source}</StyledInfo>
              <StyledInfo>
                Contact:&nbsp;
                <a
                  href="mailto:insightssupport@elexon.co.uk"
                  className="is-link"
                >
                  insightssupport@elexon.co.uk
                </a>
              </StyledInfo>
            </div>
            <div className="column">
              <StyledTitle>API documentation</StyledTitle>
              <StyledInfo>
                The API endpoints are described in the API Documentation
              </StyledInfo>
              <TextLink
                linkTo={getDocumentationLink(documentationLinkSlug)}
                linkText="View documentation"
              />
            </div>
          </div>
        </div>
      </FullWidthSection>
    </AdditionalInfo>
  );
};

export default AdditionalInfoSection;
