import PageMeta from "components/components/Meta/PageMeta";
import SurplusAndMarginChartContainer from "components/dataVisualization/SurplusAndMargin/SurplusAndMarginChartContainer/SurplusAndMarginChartContainer";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import React from "react";

const SurplusAndMargin: React.FC = () => (
  <>
    <PageMeta title="Surplus forecast and margin" />
    <PageHeader
      title="Surplus forecast and margin"
      pageDescription={
        <p>
          National Grid ESO derives the National Surplus Forecast and Generating
          Plant Operating Margin reports and presents both reports as daily MW
          values from 2 days to 14 days ahead and weekly MW values for the 2
          weeks to 156 weeks ahead.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            NGESO creates a forward view of generation availability (also
            referred to as Output Usable data under the Grid Code) for
            generation and interconnector capacity, accounting for planned
            outages; this generation availability is reported as generation
            forecasts. While publishing the generation availability, NGESO also
            assess this against the actual GB electricity system requirements
            and provides the Generating Plant Operating Margin, which is the
            additional generation or reserve, on top of the total Demand
            Forecast in order to meet the actual demand in real time.
          </p>
          <p>
            NGESO also reports National Surplus Forecast which is derived from
            the difference of the total aggregate generation availability from
            the GB electricity system requirements (sum of Generating Plant
            Operating Margin and Demand Forecast).
          </p>
          <p>
            The Surplus Forecast and Margin data can be downloaded as 2-14 days,
            2-52 weeks or 2-156 weeks head. The 2-52 weeks is a subset of the
            2-156 weeks data. Further detail on the naming for each data types
            for each time resolution is shown in the data export window tooltip.
          </p>
        </>
      }
    />
    <FullWidthChartSection>
      <SurplusAndMarginChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="forecast/margin/daily" />
  </>
);

export default SurplusAndMargin;
