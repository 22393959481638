import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import TabBody from "components/components/Tabs/TabBody/TabBody";
import TabSelectors from "components/components/Tabs/TabSelectors/TabSelectors";
import React from "react";

export interface TabDetails<T> {
  title: T;
  pageTitle?: string;
  children: JSX.Element;
}

export const BalancingMechanismTabNameArray = [
  "Physical",
  "Dynamic",
  "Bid Offer",
  "Balancing Services Volume",
] as const;

export type BalancingMechanismTabName =
  typeof BalancingMechanismTabNameArray[number];

export enum EventTabEnum {
  SystemWarnings = "System Warnings",
  DemandControlInstructions = "Demand Control Instructions",
}

export enum WidthType {
  Wide,
  SemiWide,
  NotWide,
}

export type EventTabName = `${EventTabEnum}`;

export enum DerivedDataTabEnum {
  BidAcceptance = "Bid Acceptance Volume",
  OfferAcceptance = "Offer Acceptance Volume",
  IndicativeVolume = "Indicative Period BM Unit Acceptance Volumes",
  IndicativeCashflows = "Indicative Period BM Unit Cashflows",
}

export const derivedDataTabDocumentationLinkDict: {
  [key in DerivedDataTabEnum]: string;
} = {
  [DerivedDataTabEnum.BidAcceptance]:
    "balancing/settlement/acceptance/volumes/all/%7BbidOffer%7D/%7BsettlementDate%7D",
  [DerivedDataTabEnum.OfferAcceptance]:
    "balancing/settlement/acceptance/volumes/all/%7BbidOffer%7D/%7BsettlementDate%7D",
  [DerivedDataTabEnum.IndicativeVolume]:
    "balancing/settlement/indicative/volumes/all/%7BbidOffer%7D/%7BsettlementDate%7D",
  [DerivedDataTabEnum.IndicativeCashflows]:
    "balancing/settlement/indicative/cashflows/all/%7BbidOffer%7D/%7BsettlementDate%7D",
};

export type DerivedDataTabName = `${DerivedDataTabEnum}`;

export enum DetailedSystemPricesTabEnum {
  BuyStack = "Buy stack",
  SellStack = "Sell stack",
}

export type DetailedSystemPricesTabName = `${DetailedSystemPricesTabEnum}`;

export type TabName =
  | BalancingMechanismTabName
  | EventTabName
  | DerivedDataTabName
  | DetailedSystemPricesTabName;

interface Props<T> {
  showDefault: boolean;
  defaultMessage?: string;
  tabs: TabDetails<T>[];
  activeTabTitle: T;
  setActiveTabTitle: React.Dispatch<React.SetStateAction<T>>;
  widthTypeTab?: WidthType;
  widthTypeTabBody?: WidthType;
}

const Tabs = <T extends TabName>({
  showDefault,
  defaultMessage,
  tabs,
  activeTabTitle,
  setActiveTabTitle,
  widthTypeTab = WidthType.SemiWide,
  widthTypeTabBody = WidthType.SemiWide,
}: Props<T>): JSX.Element => (
  <>
    <FullWidthSection className="is-grey-section is-grey-border-bottom">
      <TabSelectors
        widthTypeTab={widthTypeTab}
        tabs={tabs}
        activeTabTitle={activeTabTitle}
        setActiveTabTitle={setActiveTabTitle}
      />
    </FullWidthSection>
    <TabBody
      widthType={widthTypeTabBody}
      tabs={tabs}
      activeTabTitle={activeTabTitle}
      showDefault={showDefault}
      defaultMessage={defaultMessage ?? ""}
    />
  </>
);

export default Tabs;
