import { FuelTypeGrouping } from "models/generationByFuelType/FuelTypeGroupings";
import { GenerationByFuelTypeRawSummaryDataModel } from "models/generationByFuelType/GenerationByFuelTypeRawSummaryDataModel";
import GenerationByFuelTypeSummaryTableModel from "models/generationByFuelType/GenerationByFuelTypeSummaryTableModel";
import { GenerationByFuelTypeSummaryTileModel } from "models/generationByFuelType/GenerationByFuelTypeSummaryTileModel";
import { fuelTypesMap } from "utils/FuelTypeMap";
import { formatMWhAsGWh } from "utils/summaryHelpers";

export function createTwentyFourHourSummary(
  fuelTypeModels: GenerationByFuelTypeRawSummaryDataModel[]
): GenerationByFuelTypeSummaryTileModel {
  const summary = fuelTypeModels.reduce<Record<string, number>>(
    (accumulator, fuelTypeModel) => {
      if (fuelTypesMap[fuelTypeModel.fuelType]) {
        const category = fuelTypesMap[fuelTypeModel.fuelType].grouping;
        accumulator[category] =
          (accumulator[category] || 0) + fuelTypeModel.twentyFourHourUsage;
      }
      return accumulator;
    },
    {}
  );

  const lowCarbons = summary[FuelTypeGrouping.lowCarbon] || 0;
  const fossilFuels = summary[FuelTypeGrouping.fossilFuel] || 0;
  const interconnectors = summary[FuelTypeGrouping.interconnector] || 0;
  const other = summary[FuelTypeGrouping.other] || 0;
  const total = lowCarbons + fossilFuels + interconnectors + other;

  return {
    lowCarbons: formatMWhAsGWh(lowCarbons),
    fossilFuels: formatMWhAsGWh(fossilFuels),
    interconnectors: formatMWhAsGWh(interconnectors),
    other: formatMWhAsGWh(other),
    total: formatMWhAsGWh(total),
  };
}

export function mapToGenerationByFuelTypeSummaryDataModels(
  dataModels: GenerationByFuelTypeRawSummaryDataModel[]
): GenerationByFuelTypeSummaryTableModel[] {
  return dataModels.reduce(
    (accumulator: GenerationByFuelTypeSummaryTableModel[], model) => {
      if (fuelTypesMap[model.fuelType]) {
        const fuelTypeMapping = fuelTypesMap[model.fuelType];
        accumulator.push({
          fuelType: model.fuelType,
          currentUsage: model.currentUsage,
          currentPercentage: model.currentPercentage,
          halfHourUsage: model.halfHourUsage,
          halfHourPercentage: model.halfHourPercentage,
          twentyFourHourUsage: model.twentyFourHourUsage,
          twentyFourHourPercentage: model.twentyFourHourPercentage,
          fuelTypeName: fuelTypeMapping.displayName,
          fuelTypeGrouping: fuelTypeMapping.grouping,
        });
      }
      return accumulator;
    },
    []
  );
}
