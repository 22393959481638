import DateSelector from "components/components/DateSelector/DateSelector";
import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import PageMeta from "components/components/Meta/PageMeta";
import Tabs, {
  EventTabName,
  TabDetails,
} from "components/components/Tabs/Tabs/Tabs";
import { StyledDiv } from "components/components/balancingMechanism/marketView/MarketViewFilters/style";
import CheckboxDataExporter from "components/dataVisualization/CheckboxDataExporter/CheckboxDataExporter";
import DemandControlInstructionsTab from "components/dataVisualization/Events/DemandControlInstructions/DemandControlInstructionsTab";
import SystemWarningsTab from "components/dataVisualization/Events/SystemWarnings/SystemWarningsTab";
import {
  datasets,
  transformEventDownloadCallbacks,
} from "components/dataVisualization/Events/utils";
import { ChartFooter } from "components/dataVisualization/chartComponents/style";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useState } from "react";
import {
  addDaysToDate,
  ceilToHalfHour,
  MAX_DAYS_IN_ONE_YEAR,
  restrictDateFilterToMaxOneYear,
} from "utils/dateHelpers";

const Events: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(
      addDaysToDate(defaultEndDate, -7),
      new Date(defaultEndDate)
    )
  );

  const tabs: TabDetails<EventTabName>[] = [
    {
      title: "System Warnings",
      pageTitle: "System Warnings",
      children: <SystemWarningsTab dateFilter={dateFilter} />,
    },
    {
      title: "Demand Control Instructions",
      pageTitle: "Demand Control Instructions",
      children: <DemandControlInstructionsTab dateFilter={dateFilter} />,
    },
  ];

  const [activeTabTitle, setActiveTabTitle] = useState<EventTabName>(
    tabs[0].title
  );

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel
  ): void => {
    setDateFilter(restrictDateFilterToMaxOneYear(newDateFilter));
  };

  return (
    <>
      <PageMeta title="Events" />
      <PageHeader
        title="Events"
        pageDescription={
          <p>
            This page can be used to view System Warnings and Demand Control
            Instructions. Use the controls below to select a date and time
            period to view data
          </p>
        }
      />
      <FullWidthSection className="is-grey-section">
        <StyledDiv className="container px-6">
          <h2>Time frame</h2>
          <DateSelector
            dateFilter={dateFilter}
            handleChangeToDateSelection={handleChangeToDateSelection}
            dateTabs={[]}
            activeTab={null}
            maxRange={MAX_DAYS_IN_ONE_YEAR}
          />
        </StyledDiv>
      </FullWidthSection>
      <Tabs
        tabs={tabs}
        showDefault={false}
        activeTabTitle={activeTabTitle}
        setActiveTabTitle={setActiveTabTitle}
      />
      <ChartFooter rightAligned>
        <CheckboxDataExporter
          dateFilter={dateFilter}
          datasets={datasets}
          transformToDownloadCallbacks={(
            datasetsToDownload: string[]
          ): DownloadCallback[] =>
            transformEventDownloadCallbacks(datasetsToDownload, dateFilter)
          }
        />
      </ChartFooter>
    </>
  );
};

export default Events;
