import { getActualGenerationPerTypeSummaryData } from "api/dataVisualisation/actualGenerationPerType/actualGenerationPerType";
import LoadingButton from "components/components/Button/LoadingButton";
import InformationTooltip from "components/components/InformationTooltip/InformationTooltip";
import {
  TooltipLabel,
  TooltipLine,
  TooltipWrapper,
} from "components/components/InformationTooltip/style";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import AgptSummaryTable from "components/dataVisualization/actualGenerationPerType/AgptSummaryTile/AgptSummaryTable";
import SummaryTile from "components/dataVisualization/dataPageComponents/SummaryTile/SummaryTile";
import {
  Header,
  MajorValueCell,
  SummaryTable,
  ValueCell,
} from "components/dataVisualization/dataPageComponents/SummaryTile/commonStyle";
import {
  AgptTotalsModel,
  AgptSummaryData,
} from "models/actualGenerationModel/actualGeneration";
import { useEffect, useState } from "react";

import { calculate24hTotals, splitSummaryDataIntoCategories } from "./utils";

const AgptSummaryTile: React.FC = () => {
  const [summaryDisplayData, setSummaryDisplayData] =
    useState<AgptTotalsModel | null>();
  const [summaryTableData, setSummaryTableData] = useState<AgptSummaryData>();
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [summaryTime, setSummaryTime] = useState(new Date());

  useEffect(() => {
    getActualGenerationPerTypeSummaryData()
      .then((summaryData) => {
        const categorisedData = splitSummaryDataIntoCategories(summaryData);
        setSummaryDisplayData(calculate24hTotals(categorisedData));
        setSummaryTableData(categorisedData);
        setSummaryTime(new Date());
      })
      // TODO 36876: Add error handling for failed calls to the API
      // eslint-disable-next-line no-console
      .catch(console.log);
  }, []);

  return (
    <SummaryTile>
      <>
        {summaryTableData && (
          <Modal
            size={ModalSize.xLarge}
            isActive={showSummaryModal}
            onClose={(): void => setShowSummaryModal(false)}
            headingId="summary-modal-header"
            descriptionId="summary-modal-description"
          >
            <AgptSummaryTable
              data={summaryTableData}
              summaryTime={summaryTime}
              onClose={(): void => setShowSummaryModal(false)}
            />
          </Modal>
        )}
        <SummaryTable tableLayout="auto">
          <thead>
            <tr>
              <Header colSpan={2}>24 hour snapshot</Header>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TOTAL</td>
              <MajorValueCell
                className="right-align"
                data-test-id="summary-tile-data-total"
              >
                {summaryDisplayData ? summaryDisplayData.total : "—"}
              </MajorValueCell>
            </tr>
            <tr>
              <td>
                <TooltipWrapper>
                  Low Carbons
                  <InformationTooltip>
                    <TooltipLine>
                      <TooltipLabel>Low Carbons: </TooltipLabel>
                      Biomass, Hydro Pumped Storage, Hydro Run-of-river and
                      poundage, Nuclear, Other, Solar, Wind Offshore, Wind
                      Onshore
                    </TooltipLine>
                  </InformationTooltip>
                </TooltipWrapper>
              </td>
              <ValueCell
                className="right-align"
                data-test-id="summary-tile-data-low-carbons"
              >
                {summaryDisplayData ? summaryDisplayData.lowCarbon : "—"}
              </ValueCell>
            </tr>
            <tr>
              <td>
                <TooltipWrapper>
                  Fossil Fuels
                  <InformationTooltip>
                    <TooltipLine>
                      <TooltipLabel>Fossil Fuels: </TooltipLabel>
                      Fossil Gas, Fossil Hard coal, Fossil Oil
                    </TooltipLine>
                  </InformationTooltip>
                </TooltipWrapper>
              </td>
              <ValueCell
                className="right-align"
                data-test-id="summary-tile-data-fossil-fuels"
              >
                {summaryDisplayData ? summaryDisplayData.fossilFuel : "—"}
              </ValueCell>
            </tr>
            <tr data-test-id="summary-tile-open-button-row">
              <ValueCell colSpan={2}>
                <LoadingButton
                  onClick={(): void => setShowSummaryModal(true)}
                  buttonText="daily view"
                  isLoading={summaryTableData === undefined}
                />
              </ValueCell>
            </tr>
          </tbody>
        </SummaryTable>
      </>
    </SummaryTile>
  );
};

export default AgptSummaryTile;
