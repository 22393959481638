import {
  AgptTotalsModel,
  AgptSummaryModel,
  AgptSummaryData,
  FossilPsrTypes,
} from "models/actualGenerationModel/actualGeneration";
import { formatMWhAsGWh } from "utils/summaryHelpers";

export const splitSummaryDataIntoCategories = (
  data: AgptSummaryModel[]
): AgptSummaryData => {
  const lowCarbonData = data.filter(
    (item) => !FossilPsrTypes.includes(item.psrType)
  );
  const fossilFuelData = data.filter((item) =>
    FossilPsrTypes.includes(item.psrType)
  );

  return {
    all: data,
    lowCarbon: lowCarbonData,
    fossilFuel: fossilFuelData,
  };
};

export const calculate24hTotals = (
  summaryData: AgptSummaryData
): AgptTotalsModel => {
  const allTotal = summaryData.all.reduce<number>(
    (accumulator, value) => accumulator + value.twentyFourHourUsage,
    0
  );
  const lowCarbonTotal = summaryData.lowCarbon.reduce<number>(
    (accumulator, value) => accumulator + value.twentyFourHourUsage,
    0
  );
  const fossilFuelTotal = summaryData.fossilFuel.reduce<number>(
    (accumulator, value) => accumulator + value.twentyFourHourUsage,
    0
  );

  return {
    total: formatMWhAsGWh(allTotal),
    lowCarbon: formatMWhAsGWh(lowCarbonTotal),
    fossilFuel: formatMWhAsGWh(fossilFuelTotal),
  };
};
