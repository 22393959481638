import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import HeaderNav from "components/components/HeaderNav/HeaderNav";
import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import RemitMessageDetailsContent from "components/remit/MessageDetails/RemitMessageDetailsContent/RemitMessageDetailsContent";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import React from "react";
import routeConstants from "utils/routeConstants";

import { PageContainer } from "./style";

export interface SelectedMessageRevision {
  messageId: number;
  messageData: RemitMessageDetailsModel | undefined | null;
}

const pageTitle = "Message details";

const RemitMessageDetails: React.FC = () => (
  <PageContainer>
    <PageMeta title={pageTitle} />
    <HeaderNav
      title={pageTitle}
      breadcrumbStep={{
        title: "REMIT",
        route: routeConstants.remit,
      }}
      isFullWidth={true}
    />
    <FullWidthSection>
      <h1 className="title" data-test-id="pageheader-title-content">
        Message details
      </h1>
      <RemitMessageDetailsContent />
    </FullWidthSection>
    <AdditionalInfoSection
      isFullWidth={true}
      documentationLinkSlug="REMIT/%7BmessageId%7D"
    />
  </PageContainer>
);

export default RemitMessageDetails;
