import { BalancingMechanismTabName } from "components/components/Tabs/Tabs/Tabs";
import TextLinkWithQueryParams from "components/components/TextLinkWithQueryString/TextLinkWithQueryParams";
import React from "react";
import {
  addMinsToDate,
  endOfUkDay,
  isToday,
  startOfUkDay,
} from "utils/dateHelpers";
import routeConstants from "utils/routeConstants";

export const getBmUnitLinkRenderFn = (
  bmUnit: string,
  params: Record<string, string>
): JSX.Element => {
  const finalParams = {
    ...(bmUnit && { bmuId: bmUnit }),
    ...params,
  };
  const queryParams = new URLSearchParams(finalParams);
  return (
    <TextLinkWithQueryParams
      urlBase={routeConstants.bmuView}
      queryParams={queryParams}
      linkText={bmUnit}
      openInANewTab
    />
  );
};

export const buildBmuViewQueryParamsWithoutBmuId = (
  selectedDate: Date | undefined,
  activeTab: BalancingMechanismTabName
): Record<string, string> => {
  const endDate =
    selectedDate !== undefined && isToday(selectedDate)
      ? addMinsToDate(selectedDate, 30).toISOString()
      : endOfUkDay(selectedDate).toISOString();

  return {
    activeTab: activeTab,
    ...(selectedDate && {
      startDate: startOfUkDay(selectedDate).toISOString(),
      endDate,
    }),
  };
};
