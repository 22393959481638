import { Datum, Serie, Point } from "@nivo/line";
import { IndicativeDemandPeakChartData } from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/useIndicativePeakDemandData";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import DateOnlyFilterModel from "models/filterModels/dateOnlyFilterModel";
import { IndicativeDemandPeakModel } from "models/indicativeDemandPeak/indicativeDemandPeak";
import {
  pointShapeForIndicativeDemandPeakDataset,
  indicativeDemandPeakDatasetCategories,
  TriadPeak,
  TriadPeakDataset,
} from "models/indicativePeakDemandChartData";
import { indicativeDemandPeakChartColours } from "styles/colours";
import {
  formatDateTimeString,
  addMinsToDate,
  DateFormat,
} from "utils/dateHelpers";

export const hideDailyDemandDatasetOnPeakDates = (
  slicePoints: Point[],
  peaks: TriadPeak[]
): DatasetCategory<ChartDatasetModel>[] => {
  const sliceDate = slicePoints[0].data.x as Date;
  const peakId = peaks
    .filter((peak) => peak.date.getTime() === sliceDate.getTime())
    .map((peak) => peak.id);

  return indicativeDemandPeakDatasetCategories.map((category) => ({
    ...category,
    datasets: category.datasets.filter(
      (dataset) =>
        dataset.dataKey ===
        (peakId.length === 0 ? "dailyDemandPeak" : peakId[0])
    ),
  }));
};

export const getTriadPeaksFromRawPeakData = (
  rawTriads: IndicativeDemandPeakModel[]
): TriadPeak[] =>
  rawTriads
    .sort(({ demand: peakValueA }, { demand: peakValueB }) =>
      peakValueA < peakValueB ? 1 : -1
    )
    .map(
      (
        {
          settlementDate,
          demand,
          settlementPeriod,
          halfHourEndTime,
          settlementRunType,
        },
        index
      ) => {
        const time = addMinsToDate(new Date(halfHourEndTime), -30);
        return {
          id: `peak${index + 1}` as TriadPeakDataset,
          date: new Date(settlementDate),
          demand: Math.round(demand),
          position: index + 1,
          settlementPeriod,
          settlementRun: settlementRunType ?? "—",
          time: formatDateTimeString(time, DateFormat.TimeOnly),
        };
      }
    );

export const getDateRangeFromDemandData = (
  demandData: Datum[]
): DateFilterModel => {
  if (demandData.length === 0) {
    return new DateOnlyFilterModel(
      new Date(),
      new Date()
    ).toDateFilterModelInclusive();
  }
  const timeValues = demandData.map((datum) => (datum.x as Date).getTime());
  const start = new Date(Math.min(...timeValues));
  const end = new Date(Math.max(...timeValues));

  return new DateOnlyFilterModel(start, end).toDateFilterModelInclusive();
};

export const transformIndicativeDemandPeakModelData = (
  rawData: IndicativeDemandPeakChartData
): { series: Serie[]; triadPeaks: TriadPeak[]; dateRange: DateFilterModel } => {
  const dailyDemandPeakData = rawData.timeSeries.reduce((prev, curr) => {
    const startTime = new Date(curr.halfHourEndTime);
    startTime.setMinutes(startTime.getMinutes() - 30);
    return [
      ...prev,
      {
        x: new Date(curr.settlementDate),
        y: Math.round(curr.demand),
        pointShape: pointShapeForIndicativeDemandPeakDataset.dailyDemandPeak,
        settlementPeriod: curr.settlementPeriod,
        settlementRun: curr.settlementRunType,
        startTime,
      },
    ];
  }, [] as Datum[]);

  const triadPeaks = getTriadPeaksFromRawPeakData(rawData.triadPeaks);

  const peakValueSeries: Serie[] = triadPeaks.map(
    ({ id, date, demand, settlementPeriod, settlementRun }) => ({
      data: [
        {
          x: date,
          y: Math.round(demand),
          isPeak: true,
          pointShape: pointShapeForIndicativeDemandPeakDataset[id],
          settlementPeriod,
          settlementRun,
        },
      ],
      id,
      colour: indicativeDemandPeakChartColours[id],
    })
  );

  return {
    series: [
      {
        id: "dailyDemandPeak",
        colour: indicativeDemandPeakChartColours.dailyDemandPeak,
        data: dailyDemandPeakData,
        pointShape: pointShapeForIndicativeDemandPeakDataset.dailyDemandPeak,
      },
      ...peakValueSeries,
    ],
    triadPeaks,
    dateRange: getDateRangeFromDemandData(dailyDemandPeakData),
  };
};
