import DateFilterModel from "models/filterModels/dateFilterModel";
import {
  getSettlementPeriodFromSettlementTime,
  normaliseDateToMinuteInterval,
} from "utils/DateUtils";

// region CONSTANTS
// Earliest date that we are required to show for custom date input on charts and data export.
// The website user will not be able to enter a date earlier than this:
export const EARLIEST_DATE = new Date("2016-01-01T00:00Z");

interface Duration {
  months: number;
  days: number;
  hours: number;
  minutes: number;
}

export const listOfMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const HOURS_IN_ONE_DAY = 24;
// allow for change from BST to UTC
export const MAX_HOURS_IN_ONE_DAY = 25;
export const MAX_DAYS_IN_ONE_YEAR = 366;
export const MAX_DAYS_IN_ONE_MONTH = 31;
export const DAYS_IN_ONE_WEEK = 7;
export const ONE_DAY = 1;
export const THREE_DAYS = 3;
export const SIX_MONTHS_IN_DAYS = 183;

export const SECOND_IN_MS = 1000;
export const HOUR_IN_MINUTES = 60;
export const MINUTE_IN_MS = SECOND_IN_MS * 60;
export const HOUR_IN_MS = MINUTE_IN_MS * HOUR_IN_MINUTES;
export const DAY_IN_MS = HOUR_IN_MS * HOURS_IN_ONE_DAY;
export const MONTH_LOWER_BOUND_IN_MS = DAY_IN_MS * 28;
export const YEAR_LOWER_BOUND_IN_MS = DAY_IN_MS * 365;
// endregion

// region MISC HELPERS

/**
 Generates a sequence of specified length. E.g. length 5 => [0, 1, 2, 3, 4]
 */
const generateSequenceFromZero = (length: number): number[] =>
  Array.from(new Array(length).keys());

/**
 * Takes a Date object assumed to be UTC and returns `true` if that instant is during the BST period.
 * @param date Defaults to today (`new Date()`)
 */
export const isBst = (date?: Date): boolean => {
  const initialDate = date ? new Date(date) : new Date();

  const ukHour = new Date(
    initialDate.toLocaleString("en-US", { timeZone: "GB" })
  ).getHours();
  const utcHour = initialDate.getUTCHours();

  return (ukHour - utcHour + 24) % 24 == 1;
};

/**
 * Returns a Date object representing UK midnight of the given date (time of 00:00).
 * @param date Defaults to today (`new Date()`)
 */

export const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getUTCFullYear() === today.getUTCFullYear() &&
    date.getUTCMonth() === today.getUTCMonth() &&
    date.getUTCDate() === today.getUTCDate()
  );
};

/**
 * Given a year, returns the first Monday of the year (W01-1).
 */
const getFirstMondayOfYear = (year: number): Date => {
  // 4th January is always in the 1st week of the year
  const fourthJan = new Date(year, 0, 4);
  const dayOfWeek = fourthJan.getDay();
  const daysToOffset = dayOfWeek === 0 ? 7 : dayOfWeek;

  // Get the first Monday of the ISO year
  return new Date(fourthJan.setDate(4 + 1 - daysToOffset));
};

/**
 * Given a year and week (corresponding to ISO 8601 format), returns the corresponding date of the Monday of the week.
 */
export const getDateOfMondayFromWeekAndYear = (
  year: number,
  week: number
): Date => {
  const firstMondayOfYear = getFirstMondayOfYear(year);

  const dateOfMondayForRequiredWeek =
    (week - 1) * 7 + firstMondayOfYear.getDate();

  return new Date(firstMondayOfYear.setDate(dateOfMondayForRequiredWeek));
};

/**
 * Given a list of dates, return the list dates with any duplicates removed
 */
export const getUniqueDatesFromList = (dates: Date[]): Date[] => {
  return [...new Set(dates.map((date) => date.getTime()))].map(
    (date) => new Date(date)
  );
};
//endregion

// region ADD TIME TO DATE
export const addSecondsToDate = (date: Date, secondsToAdd: number): Date =>
  new Date(new Date(date).setUTCSeconds(date.getUTCSeconds() + secondsToAdd));
export const addMinsToDate = (date: Date, minsToAdd: number): Date =>
  new Date(new Date(date).setUTCMinutes(date.getUTCMinutes() + minsToAdd));
export const addHoursToDate = (date: Date, hoursToAdd: number): Date =>
  new Date(new Date(date).setUTCHours(date.getUTCHours() + hoursToAdd));
export const addDaysToDate = (date: Date, daysToAdd: number): Date =>
  new Date(new Date(date).setUTCDate(date.getUTCDate() + daysToAdd));
export const addMonthsToDate = (date: Date, monthsToAdd: number): Date =>
  new Date(new Date(date).setUTCMonth(date.getUTCMonth() + monthsToAdd));
export const addYearsToDate = (date: Date, yearsToAdd: number): Date =>
  new Date(new Date(date).setUTCFullYear(date.getUTCFullYear() + yearsToAdd));
export const addTimeToDate = (
  date: Date,
  daysToAdd: number,
  hoursToAdd: number,
  minsToAdd: number
): Date =>
  addMinsToDate(
    addHoursToDate(addDaysToDate(date, daysToAdd), hoursToAdd),
    minsToAdd
  );

// endregion

// region SPECIFIC TIME ON DATE

/**
 * Returns a Date object representing the UTC start of the given date (time of 00:00Z).
 * @param date Defaults to today (`new Date()`)
 */
export const startOfDay = (date?: Date): Date => {
  const finalDate = date !== undefined ? new Date(date) : new Date();
  finalDate.setUTCHours(0, 0, 0, 0);
  return finalDate;
};

export const startOfUkDay = (date?: Date): Date => {
  const initialDate = date ? new Date(date) : new Date();
  const utcMidnight = isBst(initialDate)
    ? startOfDay(addHoursToDate(initialDate, 1))
    : startOfDay(date);
  return isBst(utcMidnight) ? addHoursToDate(utcMidnight, -1) : utcMidnight;
};

/**
 * Returns a Date object representing the UTC end of the given date (time of 23:59:59.999Z).
 * @param date Defaults to today (`new Date()`)
 */
export const endOfDay = (date?: Date): Date => {
  const finalDate = date !== undefined ? new Date(date) : new Date();
  finalDate.setUTCHours(23, 59, 59, 999);
  return finalDate;
};

/**
 * Returns a Date object representing the end of the UK date (time of 23:59:59.999 UK time).
 * @param date Defaults to today (`new Date()`)
 */
export const endOfUkDay = (date?: Date): Date => {
  const initialDate = date ? new Date(date) : new Date();
  const endOfUtcDay = isBst(initialDate)
    ? endOfDay(addHoursToDate(initialDate, 1))
    : endOfDay(initialDate);
  return isBst(endOfUtcDay) ? addHoursToDate(endOfUtcDay, -1) : endOfUtcDay;
};

// endregion

// region GENERATE LISTS
/**
 Returns a list of years between two dates in increasing order. E.g. [2021, 2022, 2023]
 @param minDate The earliest date
 @param maxDate The latest date
 */
export const getListOfYears = (minDate: Date, maxDate: Date): number[] => {
  return generateSequenceFromZero(
    maxDate.getFullYear() - minDate.getFullYear() + 1
  )
    .map((i) => maxDate.getFullYear() - i)
    .reverse();
};

export const getAllHourStrings = (): string[] => {
  const startOfToday = new Date();
  startOfToday.setUTCHours(0, 0, 0, 0);
  return generateSequenceFromZero(HOURS_IN_ONE_DAY).map((i) =>
    addHoursToDate(startOfToday, i).getUTCHours().toString().padStart(2, "0")
  );
};

export const getIntervalMinuteStrings = (interval: number): string[] => {
  const startOfToday = new Date();
  startOfToday.setUTCHours(0, 0, 0, 0);
  const intervalCount = Math.floor(HOUR_IN_MINUTES / interval);
  return generateSequenceFromZero(intervalCount).map((i) =>
    addMinsToDate(startOfToday, i * interval)
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")
  );
};

export const getHalfHourlyTimesForDate = (date: Date): Date[] =>
  generateSequenceFromZero(HOURS_IN_ONE_DAY).flatMap((hours) => [
    new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        hours,
        0
      )
    ),
    new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        hours,
        30
      )
    ),
  ]);

// endregion

// region DATE FORMATTING
export enum DateFormat {
  DateOnly,
  DateTime,
  DateTimeWithSeconds,
  TimeOnly,
  DateTimeWithHyphen,
}

export const formatDateTimeString = (
  date: Date,
  dateFormat: DateFormat = DateFormat.DateTime
): string => {
  switch (dateFormat) {
    case DateFormat.DateOnly:
      return date.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        timeZone: "UTC",
      });
    case DateFormat.DateTimeWithSeconds:
      return date.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "UTC",
      });
    case DateFormat.TimeOnly:
      return date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
      });
    case DateFormat.DateTimeWithHyphen:
      return date
        .toLocaleString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        })
        .replace(",", " -");
    case DateFormat.DateTime:
    default:
      return date.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
      });
  }
};

/**
 * Format a date as a string (date only)
 * @param  {Date} date The date to format
 * @return {string} The date formatted as YYYY-MM-DD
 */
export const toDateOnlyString = (date: Date): string =>
  date.toISOString().split("T")[0];

export const toSettlementPeriodRangeString = (date: Date): string => {
  const dateAndStartTime = formatDateTimeString(
    date,
    DateFormat.DateTimeWithHyphen
  );
  const endTime = formatDateTimeString(
    addMinsToDate(date, 30),
    DateFormat.TimeOnly
  );
  const settlementPeriod = getSettlementPeriodFromSettlementTime(date);
  return `${dateAndStartTime}-${endTime} UTC (SP ${settlementPeriod})`;
};

/**
 * Takes in a duration represented by the number of months, days, hours, and minutes and formats
 * it into a human-readable string. It removes irrelevant components based on the duration's length
 * and returns a formatted string indicating the duration in terms of the remaining non-zero components.
 */
export const formatUTCDuration = ({
  months,
  days,
  hours,
  minutes,
}: Duration): string => {
  if (months !== 0) {
    hours = 0;
    minutes = 0;
  }

  if (days !== 0) {
    minutes = 0;
  }
  const periods = [
    { value: months, label: "month" },
    { value: days, label: "day" },
    { value: hours, label: "hour" },
    { value: minutes, label: "minute" },
  ];

  const nonZeroPeriods = periods.filter((period) => period.value !== 0);

  if (nonZeroPeriods.length === 0) {
    return "0 minutes";
  }
  return nonZeroPeriods
    .map(
      (period) =>
        `${period.value} ${period.label}${period.value > 1 ? "s" : ""}`
    )
    .join(", ");
};
// endregion

// region NEAREST HALF HOUR
export const floorToHalfHour = (date: Date): Date => {
  const flooredDate = normaliseDateToMinuteInterval(date, 30);
  if (flooredDate.getTime() === date.getTime()) {
    return flooredDate;
  }

  const newDate = new Date(date);
  newDate.setMinutes(date.getMinutes() - 30);
  return normaliseDateToMinuteInterval(newDate, 30);
};

export const ceilToHalfHour = (date: Date): Date => {
  return normaliseDateToMinuteInterval(date, 30);
};

// endregion

// region DATE DIFFERENCES
export const getDifferenceInMs = (firstDate: Date, secondDate: Date): number =>
  Math.abs(firstDate.getTime() - secondDate.getTime());

export const getNumberOfHoursBetweenDates = (d1: Date, d2: Date): number => {
  const diffMs = Math.abs(d1.getTime() - d2.getTime());
  return diffMs / HOUR_IN_MS;
};

/** Number of days between two dates
 * @param {Date} d1 The earlier date
 * @param {Date} d2 The later date
 * @returns {number} The number of days d2 - d1
 */
export const getNumberOfDaysBetweenDates = (d1: Date, d2: Date): number => {
  const date1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const date2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
  const diffMs = date2 - date1;
  return diffMs / DAY_IN_MS;
};

export const getInclusiveDatesBetween = (start: Date, end: Date): Date[] => {
  const startOfStartDate = startOfDay(start);
  const days = getNumberOfDaysBetweenDates(start, end) + 1;
  return generateSequenceFromZero(days).map((i) =>
    addDaysToDate(startOfStartDate, i)
  );
};

export const getExclusiveDatesBetween = (start: Date, end: Date): Date[] => {
  const startOfNextDate = startOfDay(addDaysToDate(start, 1));
  const days = getNumberOfDaysBetweenDates(start, end) - 1;
  return generateSequenceFromZero(days).map((i) =>
    addDaysToDate(startOfNextDate, i)
  );
};

export const getHalfHourlyTimesBetween = (start: Date, end: Date): Date[] => {
  if (end < start) {
    throw new RangeError("End date must not be before start date");
  }
  const halfHourlyTimes: Date[] = [];
  let halfHourlyTime = ceilToHalfHour(start);
  while (halfHourlyTime <= end) {
    halfHourlyTimes.push(halfHourlyTime);
    halfHourlyTime = addMinsToDate(halfHourlyTime, 30);
  }
  return halfHourlyTimes;
};

/**
 Calculates duration between two UTC dates in Months, Days, Hours and Minutes
 */
export const getDurationFromStartAndEndDatesUTC = (
  firstDate: Date,
  secondDate: Date
): Duration => {
  // Calculate the total number of months
  const getMonthCount = (d: Date): number =>
    d.getUTCFullYear() * 12 + d.getUTCMonth() + 1;

  let months = getMonthCount(secondDate) - getMonthCount(firstDate);

  // Adjust months if the day of secondDate is less than the day of firstDate
  if (secondDate.getUTCDate() < firstDate.getUTCDate()) {
    months--;
  }

  // Get a new date that will be within a month's time of the second date
  const dateFromWhichToCalculateRemainder = addMonthsToDate(
    new Date(firstDate),
    months
  );

  const totalMinutes = Math.floor(
    getDifferenceInMs(secondDate, dateFromWhichToCalculateRemainder) /
      MINUTE_IN_MS
  );

  return {
    months,
    days: Math.floor(totalMinutes / (HOUR_IN_MINUTES * HOURS_IN_ONE_DAY)),
    hours: Math.floor((totalMinutes / HOUR_IN_MINUTES) % HOURS_IN_ONE_DAY),
    minutes: Math.floor(totalMinutes % HOUR_IN_MINUTES),
  };
};
// endregion

// region DATE COMPARISON
export const getEarlier = (d1: Date, d2: Date): Date => (d1 < d2 ? d1 : d2);

export const compareDates = (previous: Date, next: Date): number => {
  return next.getTime() - previous.getTime();
};

export const areDatesOnTheSameDay = (date1: Date, date2: Date): boolean => {
  return startOfDay(date1).toISOString() === startOfDay(date2).toISOString();
};

// endregion

// region DATE CONVERSION

// The following functions create the illusion that local time is UTC for use with libraries lacking built-in UTC support.
// It is not a perfect 1:1 map as local time can omit certain times (e.g. 01:00-01:59 when clocks go forward in the UK).
const convertUtcDate = (date: Date, direction: number): Date => {
  const directionSign = Math.sign(direction);
  const offsetToUtc = date.getTimezoneOffset() * MINUTE_IN_MS;
  return new Date(date.getTime() + directionSign * offsetToUtc);
};

export const convertDateForOutput = (date: Date): Date =>
  convertUtcDate(date, -1);

export const convertDateForInput = (date: Date): Date =>
  convertUtcDate(date, 1);

// endregion

// region DATE FILTER HELPERS
/**
 * Restricts the given date filter model to a maximum duration of 1 year.
 *
 * If the given date filter model's range exceeds one year, returns a new date filter model
 * spanning one year from the same start date.
 */
export const restrictDateFilterToMaxOneYear = (
  dateFilter: DateFilterModel
): DateFilterModel => {
  const daysBetweenDates = getNumberOfDaysBetweenDates(
    dateFilter.startDate,
    dateFilter.endDate
  );

  const endDate =
    daysBetweenDates > MAX_DAYS_IN_ONE_YEAR
      ? addYearsToDate(dateFilter.startDate, 1)
      : dateFilter.endDate;

  return new DateFilterModel(dateFilter.startDate, endDate);
};

/**
 * Restricts the given date filter model to a given maximum days range.
 *
 * Preserves the same start date, but amends the end date if necessary to stay within the maximum range.
 */
export const restrictDateFilterToMaxDaysRange = (
  dateFilter: DateFilterModel,
  maxDays: number
): DateFilterModel => {
  const daysBetweenDates = getNumberOfDaysBetweenDates(
    dateFilter.startDate,
    dateFilter.endDate
  );

  const endDate =
    daysBetweenDates > maxDays
      ? addDaysToDate(dateFilter.startDate, maxDays)
      : dateFilter.endDate;

  return new DateFilterModel(dateFilter.startDate, endDate);
};

/**
 * Restricts the given date filter model to a maximum duration of 24 hours.
 *
 * Preserves the same start date, but amends the end date if necessary to stay within the maximum range.
 */
export const restrictDateFilterToTwentyFourHours = (
  dateFilter: DateFilterModel
): DateFilterModel => {
  const hoursBetweenDates = getNumberOfHoursBetweenDates(
    dateFilter.startDate,
    dateFilter.endDate
  );

  const endDate =
    hoursBetweenDates > HOURS_IN_ONE_DAY
      ? addHoursToDate(dateFilter.startDate, HOURS_IN_ONE_DAY)
      : dateFilter.endDate;

  return new DateFilterModel(dateFilter.startDate, endDate);
};

/**
 * Restricts the given date filter model to a given maximum hours range.
 *
 * Preserves the same start date, but amends the end date if necessary to stay within the maximum range.
 */
export const restrictDateFilterToMaxHoursRange = (
  dateFilter: DateFilterModel,
  maxHours: number
): DateFilterModel => {
  const hoursBetweenDates = getNumberOfHoursBetweenDates(
    dateFilter.startDate,
    dateFilter.endDate
  );

  const endDate =
    hoursBetweenDates > maxHours
      ? addHoursToDate(dateFilter.startDate, maxHours)
      : dateFilter.endDate;

  return new DateFilterModel(dateFilter.startDate, endDate);
};
// endregion
