import PageMeta from "components/components/Meta/PageMeta";
import ActualGenerationPerTypeChartContainer from "components/dataVisualization/actualGenerationPerType/ActualGenerationPerTypeChartContainer/ActualGenerationPerTypeChartContainer";
import ActualGenerationPerTypeSummaryTile from "components/dataVisualization/actualGenerationPerType/AgptSummaryTile/AgptSummaryTile";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";

const ActualGenerationPerType: React.FC = () => (
  <>
    <PageMeta title="Actual aggregated generation per type" />
    <PageHeader
      title="Actual aggregated generation per type"
      pageDescription={
        <p>
          Actual aggregated net generation output (MW) per Settlement Period and
          per production type.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <p>
          The information shall be published no later than one hour after the
          operational period. Small scale generation might be estimated.
        </p>
      }
    >
      <ActualGenerationPerTypeSummaryTile />
    </PageHeader>
    <FullWidthChartSection>
      <ActualGenerationPerTypeChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="generation/actual/per-type" />
  </>
);

export default ActualGenerationPerType;
