import { RequestStatus } from "hooks/useRequest";
import { StylesConfig, Theme } from "react-select";
import { ThemeConfig } from "react-select/dist/declarations/src/theme";
import styled from "styled-components";
import colours from "styles/colours";
import { BmuOption, IsMulti } from "utils/fuzzyBmuDataHelpers";

export const RowHeader = styled.span`
  padding-bottom: 0;
`;

export const TitleRow = styled.div`
  font-weight: bold;
  padding: 0.5em 1.8em 0.5em 0.7em;

  &&& {
    margin-bottom: 0;
  }
`;

export const DropdownContainer = styled.div`
  max-width: 60em;
`;

export const IconWithMargin = styled.span`
  margin-left: 0.4em;
  position: absolute;
`;

export const StyledInput = styled.div`
  grid-area: 1/1/2/3;

  & > div {
    display: flex;
  }
`;

export const customStyles: StylesConfig<BmuOption, IsMulti> = {
  indicatorsContainer: (provided) => ({
    ...provided,
    width: "6em",
    display: "flex",
    justifyContent: "flex-end",
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    padding: "0 5em 0 0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    cursor: "text",
    paddingLeft: 32,
  }),
};

const addErrorColours: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: colours.elexonRed, // Border colour when active
    neutral20: colours.elexonRed, // Border colour
    neutral30: colours.elexonRed, // Border colour on hover
  },
});

const addNoBmuSelectedColours: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: colours.elexonBlue, // Border colour when active
    neutral20: colours.elexonBlue, // Border colour
    neutral30: colours.elexonBlue, // Border colour on hover
    neutral50: colours.elexonBlue, // Text colour
  },
});

const addDarkerTextColour: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral50: colours.darkGrey, // Darker text colour for a11y
  },
});

export const augmentTheme = (
  theme: Theme,
  requestStatus: RequestStatus,
  noBmuSelected: boolean
): Theme => {
  switch (requestStatus) {
    case RequestStatus.SUCCESSFUL_OR_NOT_STARTED:
      return noBmuSelected ? addNoBmuSelectedColours(theme) : theme;
    case RequestStatus.ERRORED:
      return addErrorColours(theme);
    default:
      return addDarkerTextColour(theme);
  }
};
