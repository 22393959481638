import { CustomLayerProps, Point } from "@nivo/line";
import { ScaleLinear, ScaleTime } from "@nivo/scales";
import {
  LabelText,
  RectangleSlice,
} from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSlice";
import React, { useState } from "react";
import { getSettlementPeriodFromSettlementTime } from "utils/DateUtils";

const ONE_SETTLEMENT_PERIOD_IN_MS = 30 * 60 * 1000;

const generateSliceLabel = (startTime: Date, endTime: Date): LabelText => ({
  topText: `${startTime.toUTCString().slice(17, 22)}-${endTime
    .toUTCString()
    .slice(17, 22)}`,
  bottomText: `SP ${getSettlementPeriodFromSettlementTime(startTime)}`,
});

export type RectangleSliceTooltipData = {
  slicePoints: Point[];
  sliceXPosition: number;
  sliceWidth: number;
  sliceXCenterPosition: number;
  sliceInLeftHalf: boolean;
  sliceStartValue: Date;
  sliceEndValue: Date;
  sliceLabel: LabelText;
};

type RectangleSlicesLayerProps = {
  onSliceClick?: (settlementPeriodStartTime: Date) => void;
  intervalInMilliseconds?: number;
  setRectangleSliceData: (sliceData?: RectangleSliceTooltipData) => void;
  showSlices: boolean;
  idPrefix: string;
};

const isDateInFirstHalfOfDateRange = (
  dateToTest: Date,
  rangeStart: Date,
  rangeEnd: Date
): boolean =>
  dateToTest.getTime() <
  (rangeEnd.getTime() - rangeStart.getTime()) / 2 + rangeStart.getTime();

const RectangleSlicesLayerForTimeSeries: React.FC<
  RectangleSlicesLayerProps & CustomLayerProps
> = ({
  onSliceClick,
  intervalInMilliseconds = ONE_SETTLEMENT_PERIOD_IN_MS,
  setRectangleSliceData,
  xScale,
  yScale,
  points,
  showSlices,
  idPrefix,
}) => {
  const xScaleDate = xScale as ScaleTime<Date>;
  const yScaleNumber = yScale as ScaleLinear<number>;
  const [xMin, xMax] = xScaleDate.domain();
  const [svgYMin, svgYMax] = yScaleNumber.range();
  const [selectedSlice, setSelectedSlice] = useState<Date>();

  const rectangles: JSX.Element[] = [];
  let x0 = xMin;

  while (x0 < xMax) {
    const x1 = new Date(x0.getTime() + intervalInMilliseconds);
    const sliceStartValue = new Date(x0);

    const onMouseEnter = (): void => {
      setRectangleSliceData({
        slicePoints: points.filter(
          (point) =>
            (point.data.x as Date) >= sliceStartValue &&
            (point.data.x as Date) < x1
        ),
        sliceXPosition: xScaleDate(sliceStartValue),
        sliceWidth: xScaleDate(
          new Date(xMin.getTime() + intervalInMilliseconds)
        ),
        sliceXCenterPosition:
          (xScaleDate(x1) + xScaleDate(sliceStartValue)) / 2,
        sliceInLeftHalf: isDateInFirstHalfOfDateRange(
          sliceStartValue,
          xMin,
          xMax
        ),
        sliceStartValue,
        sliceEndValue: x1,
        sliceLabel: generateSliceLabel(sliceStartValue, x1),
      });
    };

    rectangles.push(
      <RectangleSlice
        y={svgYMax}
        height={svgYMin - svgYMax}
        width={xScaleDate(new Date(xMin.getTime() + intervalInMilliseconds))}
        x={xScaleDate(x0)}
        key={`${x0.getTime()}-interval`}
        id={`${idPrefix}-${x0.getTime()}-interval`}
        onClick={(): void => {
          setSelectedSlice(sliceStartValue);
          onSliceClick?.(sliceStartValue);
        }}
        labelText={generateSliceLabel(sliceStartValue, x1)}
        isSelected={selectedSlice?.getTime() === x0.getTime()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={(): void => setRectangleSliceData(undefined)}
      />
    );

    x0 = x1;
  }

  return <g style={{ opacity: showSlices ? 1 : 0 }}>{rectangles}</g>;
};

export default React.memo(RectangleSlicesLayerForTimeSeries);
