import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import GenerationByFuelTypeChartContainer from "components/dataVisualization/generationByFuelType/GenerationByFuelTypeChartContainer/GenerationByFuelTypeChartContainer";
import GenerationByFuelTypeSummaryTile from "components/dataVisualization/generationByFuelType/GenerationByFuelTypeSummaryTile/GenerationByFuelTypeSummaryTile";
import React from "react";
import { Link } from "react-router-dom";
import routeConstants from "utils/routeConstants";

const GenerationByFuelType: React.FC = () => (
  <>
    <PageMeta title="Generation by fuel type" />
    <PageHeader
      title="Generation by fuel type"
      pageDescription={
        <p>
          The Generation By Fuel type report provides our users with an
          indication of the generation outturn for Great Britain. The data is
          aggregated by Fuel Type category and updated at five minutes interval;
          the data is also available at 30 minutes interval; both are average MW
          values over 5 minutes and 30 minutes.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            The data comes from Operational Metering for BMUs connected to the
            transmission system and provides National Grid ESO real time data
            for the ESO to balance the system.
          </p>
          <p>
            The Fuel Type Categories include only imports from Interconnector
            data, any negative values (exports) are capped to zero. The{" "}
            <a
              href="https://www.elexon.co.uk/documents/data/operational-data/bmu-fuel-type/"
              data-test-id="BMU-fuel-type-spreadsheet"
            >
              BMU-Fuel Type spreadsheet
            </a>{" "}
            provides a mapping for which BMUs makes up specific fuel types; the
            fuel type categorisation by BM Unit is undertaken by National Grid
            ESO using the primary fuel type of each power station and is not
            based on the BM Unit registration data held by Elexon. If you are
            interested in both import and export data for interconnectors, you
            can use the{" "}
            <Link to={routeConstants.interconnectorFlows}>
              Interconnector flows
            </Link>{" "}
            page.
          </p>
          <p>
            The renewables outturn values are underestimated in this report
            because they exclude embedded generation and wind farms which do not
            have Operational Meters. As of 2021, National Grid ESO estimates
            this representation of wind farms with Operational Meters to be
            approximately 70%.
          </p>
        </>
      }
    >
      <GenerationByFuelTypeSummaryTile />
    </PageHeader>
    <FullWidthChartSection>
      <GenerationByFuelTypeChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection documentationLinkSlug="datasets/FUELINST" />
  </>
);

export default GenerationByFuelType;
