import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import PageMeta from "components/components/Meta/PageMeta";
import SettlementPeriodSelector from "components/components/SettlementPeriodSelector/SettlementPeriodSelector";
import Tabs, {
  BalancingMechanismTabName,
  TabDetails,
} from "components/components/Tabs/Tabs/Tabs";
import MarketViewFilters from "components/components/balancingMechanism/marketView/MarketViewFilters/MarketViewFilters";
import MarketViewBalancingServicesVolume from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/marketView/MarketViewBalancingServicesVolume";
import MarketViewBidOffer from "components/dataVisualization/balancingMechanismTabs/BidOffer/marketView/MarketViewBidOffer";
import MarketViewDataExporter from "components/dataVisualization/balancingMechanismTabs/DataExporter/marketView/MarketViewDataExporter";
import MarketViewDynamic from "components/dataVisualization/balancingMechanismTabs/Dynamic/marketView/MarketViewDynamic";
import MarketViewPhysical from "components/dataVisualization/balancingMechanismTabs/Physical/marketView/MarketViewPhysical";
import { ChartFooter } from "components/dataVisualization/chartComponents/style";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import { MarketViewContextProvider } from "contexts/MarketViewContext";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  addHoursToDate,
  addMinsToDate,
  floorToHalfHour,
} from "utils/dateHelpers";

const MarketView: React.FC = () => {
  const location = useLocation();
  // We receive data within 5 minutes after gate closure, which is an hour before the start of
  // the settlement period. So this is the latest settlement period we should have data for.
  const defaultDate = addHoursToDate(
    floorToHalfHour(addMinsToDate(new Date(), -5)),
    1
  );
  const [selectedDate, setSelectedDate] = useState<Date>(defaultDate);

  const tabs: TabDetails<BalancingMechanismTabName>[] = [
    {
      title: "Physical",
      pageTitle: "Physical data",
      children: <MarketViewPhysical />,
    },
    {
      title: "Dynamic",
      pageTitle: "Dynamic data",
      children: <MarketViewDynamic />,
    },
    {
      title: "Bid Offer",
      pageTitle: "Bid offer data",
      children: <MarketViewBidOffer />,
    },
    {
      title: "Balancing Services Volume",
      pageTitle: "Balancing services volume data",
      children: <MarketViewBalancingServicesVolume />,
    },
  ];

  const [activeTabTitle, setActiveTabTitle] =
    useState<BalancingMechanismTabName>(tabs[0].title);
  const [locationKey, setLocationKey] = useState(0);

  useEffect(() => {
    if (history.state?.query !== location.search) {
      setLocationKey((k) => k + 1);
    } else {
      history.replaceState({ query: 0 }, "");
    }
  }, [location]);

  return (
    <MarketViewContextProvider
      activeTabTitle={activeTabTitle}
      selectedDate={selectedDate}
      key={locationKey}
    >
      <>
        <PageMeta title="Balancing Mechanism data by market view" />
        <PageHeader
          title="Balancing Mechanism data by market view"
          pageDescription={
            <p>
              This page can be used to view BM Unit data at Market level. Use
              the controls below to select a date and time period to view
              Physical, Dynamic, Bid Offer and Balancing Services Volume data.
              Additional filters are available to refine your view.
            </p>
          }
        />
        <FullWidthSection className="is-grey-section">
          <MarketViewFilters>
            <SettlementPeriodSelector
              selectedDate={selectedDate}
              handleChangeToDateSelection={setSelectedDate}
              maxDate={defaultDate}
            />
          </MarketViewFilters>
        </FullWidthSection>
        <Tabs
          tabs={tabs}
          showDefault={false}
          activeTabTitle={activeTabTitle}
          setActiveTabTitle={setActiveTabTitle}
        />
        <ChartFooter rightAligned>
          <MarketViewDataExporter date={selectedDate} />
        </ChartFooter>
      </>
    </MarketViewContextProvider>
  );
};

export default MarketView;
