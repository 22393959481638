import DerivedDataImage from "assets/images/whatsNew/derivedData.webp";
import DetailedSystemPricesImage from "assets/images/whatsNew/detailedSystemPrices.webp";
import SoSoImage from "assets/images/whatsNew/soso.webp";
import SystemSellBuyImage from "assets/images/whatsNew/systemSellBuy.webp";
import VideoContainer from "components/components/VideoContainer/VideoContainer";
import { Update, UpdateWithImage } from "models/updateModel";
import React from "react";
import routeConstants from "utils/routeConstants";

/*
PLEASE READ THE COMMENTS in the whatsNewPageData.ts file before changing this file.
 */

/**
 * This field is used to determine whether users have seen the most recent
 * updates, and to show them the "New Updates" toast notification if they
 * haven't. It should be updated whenever an update is added, but should not
 * be updated for typo fixes etc.
 */

export const lastUpdatedAt = new Date("2024-02-06T00:00Z").toUTCString();

export const derivedDataUpdate: UpdateWithImage = {
  title: "Derived data",
  date: new Date("2024-02-12T00:00Z"),
  link: routeConstants.derivedData,
  linkTitleOverride: "Derived data",
  imageInfo: {
    path: DerivedDataImage,
    alt: "The Insights Solution website showing Derived system-wide data",
  },
  body: (
    <p>
      This page displays the Derived system-wide data to display subsequent bid
      and offer volume acceptances from the Imbalance Settlement Calculations.
    </p>
  ),
};

export const detailedSystemPricesUpdate: UpdateWithImage = {
  title: "Detailed system prices",
  date: new Date("2024-02-12T00:00Z"),
  link: routeConstants.detailedSystemPrices,
  linkTitleOverride: "Detailed system prices",
  imageInfo: {
    path: DetailedSystemPricesImage,
    alt: "The Insights Solution website showing Detailed system prices data",
  },
  body: (
    <p>
      This page provides an indicative view of the buy and sell stacks as part
      of the imbalance settlement calculations.
    </p>
  ),
};

export const systemSellBuyPricesUpdate: UpdateWithImage = {
  title: "System sell and buy prices",
  date: new Date("2024-02-12T00:00Z"),
  link: routeConstants.systemPrices,
  linkTitleOverride: "System sell and buy prices",
  imageInfo: {
    path: SystemSellBuyImage,
    alt: "The Insights Solution website showing System sell and buy prices data",
  },
  body: (
    <p>
      This page provides an indicative view of the sell and buy prices as part
      of the imbalance settlement calculation
    </p>
  ),
};

export const sosoUpdate: UpdateWithImage = {
  title: "SO-SO trade prices",
  date: new Date("2023-11-14T00:00Z"),
  link: routeConstants.sosoTradePrices,
  linkTitleOverride: "SO-SO trade prices",
  imageInfo: {
    path: SoSoImage,
    alt: "The Insights Solution website showing SO-SO Trade Prices data",
  },
  body: (
    <p>
      Table displaying the System Operator to System Operation (SO-SO) trades
      with prices
    </p>
  ),
};

export const creditDefaultNoticeUpdate: Update = {
  title: "Credit default notices",
  date: new Date("2023-07-04T00:00Z"),
  link: routeConstants.creditDefaultNotices,
  linkTitleOverride: "Credit Default Notice",
  body: (
    <p>
      Ability to view information regarding trading parties that have entered a
      credit default level.
    </p>
  ),
};

export const remitUpdate: Update = {
  title: "REMIT",
  date: new Date("2023-06-20T00:00Z"),
  link: routeConstants.remit,
  linkTitleOverride: "REMIT",
  body: (
    <p>
      Ability to view published REMIT (Regulation on wholesale energy markets
      integrity and transparency) data. Comprehensive filter to see messages and
      their publish history. Ability to search for a specific Message ID.
    </p>
  ),
};

export const lolpdrmUpdate: Update = {
  title: "Loss of Load Probability and De-rated Margin",
  date: new Date("2023-05-23T00:00Z"),
  link: routeConstants.lolpdrm,
  linkTitleOverride: "LoLP & DRM",
  body: (
    <p>
      Graphical visualisation displaying the LoLP and DRM all forecast data for
      the day ahead as well as historical forecast (1 hour ahead) on the same
      display.
    </p>
  ),
};

export const marketViewUpdate: Update = {
  title: "Balancing Mechanism data by market view",
  date: new Date("2023-05-05T00:00Z"),
  link: routeConstants.marketView,
  linkTitleOverride: "Market view",
  body: (
    <p>
      Ability to view BM unit data at Market View level set to explore Physical,
      Dynamic, Bid Offer Data and Balancing Services Volume data. Fuzzy search
      lead party name or BMU type to find data quickly. Ability to click on the
      table to see the individual BM unit data in more detail.
    </p>
  ),
};

export const apiDocsUpdate: Update = {
  title: "Developer Portal interface facelift",
  date: new Date("2023-02-17T00:00Z"),
  link: routeConstants.apiDocs,
  linkTitleOverride: "API documentation",

  body: (
    <p>
      We have redesigned the user interface for the Developer Portal to improve
      ease of use and search capability for API resources.
    </p>
  ),
};

export const bmuPhysicalUpdate: Update = {
  title: "Physical data added to the BMU view page",
  date: new Date("2022-11-18T00:00Z"),
  link: routeConstants.bmuView,
  linkTitleOverride: "BMU view",
  body: (
    <p>
      A set of visualisations and dashboards to explore Physical, Dynamic, Bid
      Offer Data and Balancing Services Volume data by BM Unit. Fuzzy search by
      BMU ID, lead party name or NGC BMU name to find BMUs quickly. Ability to
      click on the charts to see the data in more detail.
    </p>
  ),
};

export const irisUpdate: Update = {
  title: "Insights Real-time Information Service (IRIS)",
  date: new Date("2022-11-18T00:00Z"),
  link: routeConstants.iris,
  linkTitleOverride: "IRIS",
  body: (
    <p>
      IRIS is a near real-time, free, publicly available push service for
      accessing Insights Solution data. It will allow you to automatically
      receive data just seconds after we get it. Get started within minutes.
    </p>
  ),
};

export const accountsUpdate: Update = {
  title: "Insights Solution Accounts",
  date: new Date("2022-11-18T00:00Z"),
  link: routeConstants.iris,
  linkTitleOverride: "IRIS",
  body: (
    <p>
      In order to use IRIS, you will need to create an Insights Solution account
      to manage your IRIS client credentials.
    </p>
  ),
};

export const disbsadUpdate: Update = {
  title: "Adjustment Actions (DISBSAD)",
  date: new Date("2022-11-18T00:00Z"),
  link: routeConstants.adjustmentActionsDisbsad,
  linkTitleOverride: "adjustment actions (DISBSAD)",
  body: (
    <p>
      Visualize the price and volumes of adjustment actions across the market.
      Ability to click on individual settlement periods to see a disaggregated
      breakdown of all adjustment actions in more detail.
    </p>
  ),
};

export const nonBmStorUpdate: Update = {
  title: "Short term operating reserves",
  date: new Date("2022-10-05T00:00Z"),
  link: routeConstants.shortTermOperatingReserves,
  body: (
    <p>
      This improves on the presentation of Short Term Operating Reserves
      instructed volumes from National Grid ESO, with a summary tile showing the
      last three instructions as well as ability to visualise over period of a
      week.
    </p>
  ),
};

export const windGenUpdate: Update = {
  title: "Wind generation",
  date: new Date("2022-05-29T00:00Z"),
  link: routeConstants.windGeneration,
  body: (
    <p>
      The Wind generation forecast graphs on the Insights Solution brings a
      refreshed data visualisation and includes features such as additional
      forecast day, summary tile for peak forecast, time stamps on time of
      forecasts and users can also download historical data (as compared to data
      on existing Wind generation forecast on{" "}
      <a href="https://www.bmreports.com/bmrs/?q=generation/windforcast/out-turn">
        BMRS
      </a>
      ).
    </p>
  ),
};

export const demandForecastUpdate: Update = {
  title: "Demand forecast",
  date: new Date("2022-05-17T00:00Z"),
  link: routeConstants.demandForecast,
  body: (
    <p>
      Clearer visualisation and combining forecast data for different timescales
      on the same webpage. The page also includes summary tiles for peak demand
      as well as an overlay of Demand outturn providing additional comparison of
      forecast against historical demand.
    </p>
  ),
};

export const systemFrequencyUpdate: Update = {
  title: "Rolling system frequency",
  date: new Date("2022-05-16T00:00Z"),
  link: routeConstants.rollingSystemFrequency,
  body: (
    <p>
      Displaying the frequency data points with reference threshold for easier
      interpretation of the frequency data.
    </p>
  ),
};

export const indicatedForecastsUpdate: Update = {
  title: "Indicated forecasts",
  date: new Date("2022-05-16T00:00Z"),
  link: routeConstants.indicatedForecasts,
  body: (
    <p>
      Providing a logical grouping of indicated forecast data (generation,
      demand, imbalance and margin) as well as displaying the National demand
      forecast on the same graph for ease of analysis of related datasets.
    </p>
  ),
};

export const demandOutturnUpdate: Update = {
  title: "Demand outturn",
  date: new Date("2022-05-10T00:00Z"),
  link: routeConstants.demandOutturn,
  body: (
    <p>
      The Initial Demand Outturn measures the half-hour average MW demand
      metered by the Transmission Company based on its operational metering. The
      data is updated every 30 minutes and within 15 minutes of the end of the
      effective Settlement Period.
    </p>
  ),
};

export const surplusAndMarginUpdate: Update = {
  title: "Surplus and margin",
  date: new Date("2022-04-28T00:00Z"),
  link: routeConstants.surplusAndMargin,
  body: (
    <p>
      National Grid ESO derives the National Surplus Forecast and Generating
      Plant Operating Margin reports and presents both reports as daily MW
      values from 2 days to 14 days ahead and weekly MW values for the 2 weeks
      to 156 weeks ahead.
    </p>
  ),
};

export const temperatureUpdate: Update = {
  title: "Temperature",
  date: new Date("2022-04-20T00:00Z"),
  link: routeConstants.temperature,
  body: (
    <p>
      This report provides daily average GB temperature data (in Celsius) as
      well as reference temperatures (low, normal and high). This average data
      is calculated by National Grid ESO from the data retrieved from 6 weather
      stations around Britain. NGESO use this data as part of the electricity
      demand forecasting process.
    </p>
  ),
};

export const iteration2VideoUpdate: Update = {
  title: "Welcome to the Insights Solution",
  date: new Date("2022-04-20T00:00Z"),
  body: (
    <>
      <p>
        The Elexon Insights Solution is a key pillar of Elexon Kinnect, a
        digital customer-centric platform delivering a smart and flexible
        central data platform for the industry. Through a number of releases
        over the next few years, the platform will publish Balancing Mechanism
        Reporting Service (BMRS) data as well as Open Settlement Data.
      </p>
      <VideoContainer
        src="https://player.vimeo.com/video/719146699?h=66302830fa&title=0&byline=0&portrait=0"
        targetHeight="400px"
        title="Elexon Kinnect Insights Solution"
      />
    </>
  ),
};
