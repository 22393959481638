import TabModal, {
  ModalProps,
} from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/TabModal";
import React from "react";

import { DescriptionSections } from "./style";

interface PhysicalModalProps extends ModalProps {
  shouldDisplayStableLimits: boolean;
}

const PhysicalModal: React.FC<PhysicalModalProps> = ({
  isOpen,
  close,
  shouldDisplayStableLimits,
}) => (
  <TabModal
    title="Physical Data"
    headingId="physical-modal-header"
    isOpen={isOpen}
    close={close}
  >
    <DescriptionSections>
      <dt>Final Physical Notification (FPN)</dt>
      <dd>
        Lead Parties are required to submit Physical Notification data for their
        BM Units(s) to National Grid ESO. The final Physical Notication data is
        data present at Gate Closure.
      </dd>
      <dd>
        A Physical Notification is the best estimate of the level of generation
        or demand that a BM Unit is expected to export or import in a Settlement
        Period. Gate closure is 1 hour prior to the start of a settlement
        period.
      </dd>
      <dd>
        If no FPN Data has been received for a particular Settlement Period the
        FPN may have been defaulted by National Grid ESO to the previously
        submitted IPN Data as per agreed rules in the BSC. At least one FPN is
        published per BM Unit for each Settlement Period. FPNs are submitted as
        a series of MW values and associated times in UTC.
      </dd>

      <dt>Quiescent Physical Notification (QPN)</dt>
      <dd>
        A series of MW values and associated times in UTC expressing the volume
        of generation or demand expected to be generated or consumed (as
        appropriate) by an underlying process that forms part of the operation
        of a particular BM Unit.
      </dd>
      <dd>
        QPN values are not used in Settlement, but if submitted to the System
        Operator are deducted from Physical Notifications to determine a net
        operating level. Submission of QPNs is optional.
      </dd>

      <dt>Maximum Export Limit (MEL)</dt>
      <dd>
        The maximum power export level of a particular BM Unit at a particular
        time. The data is updated every 30 minutes and within 15 minutes of the
        end of the effective Settlement Period. MELs are submitted as a series
        of MW values and associated times in UTC.
      </dd>

      <dt>Maximum Import Limit (MIL)</dt>
      <dd>
        The minimum power import level of a particular BM Unit at a particular
        time. The data is updated every 30 minutes and within 15 minutes of the
        end of the effective Settlement Period. MILs are submitted as a series
        of MW values and associated times in UTC.
      </dd>

      {shouldDisplayStableLimits && (
        <>
          <dt>Stable Limits</dt>
          <dd>
            Stable Export Limit (SEL) is a positive MW value, expressing the
            minimum stable operating level at which a particular BM Unit can
            export power to the transmission system.
          </dd>
          <dd>
            Stable Import Limit (SIL) is a negative MW value, expressing the
            minimum stable operating level at which a particular BM Unit can
            import power from the transmission system.
          </dd>
        </>
      )}

      <dt>Bid-Offer Acceptance Level Data (BOAL)</dt>
      <dd>
        A formalised representation of the purchase and/or sale of Bids and/or
        Offers by the National Grid ESO in its operation of the Balancing
        Mechanism. Each Bid-Offer Acceptance is shown as a MW level of operation
        at the start and end time of the Acceptance (shown in UTC time).
        <ul>
          <li>
            The SO-Flag indicates whether or not the Acceptance was potentially
            taken for reasons other than balancing the short-term energy
            imbalance of the Transmission System.
          </li>
          <li>
            The STOR-Flag indicates whether or not the Acceptance was provided
            by a STOR provider.
          </li>
          <li>
            The RR Instruction Flag indicates whether the Acceptance is an RR
            Instruction (flag set to true) or a BM Acceptance (flag set to
            false).
          </li>
          <li>
            The RR Schedule Flag indicates whether or not the Acceptance is as
            the result of an RR Schedule calculation.
          </li>
        </ul>
      </dd>
    </DescriptionSections>
  </TabModal>
);

export default PhysicalModal;
