import React from "react";

import { StyledAnchor, StyledIcon, StyledText } from "./style";

interface Props {
  linkTo: string | undefined;
  linkText: string;
  external?: boolean;
}

const TextLink: React.FC<Props> = ({ linkTo, linkText, external }: Props) => {
  if (linkTo !== "undefined") {
    return (
      <StyledAnchor href={linkTo} target={external ? "_blank" : "_self"}>
        <StyledText>{linkText}</StyledText>
        <StyledIcon aria-hidden="true">V</StyledIcon>
      </StyledAnchor>
    );
  }
  return <></>;
};

export default TextLink;
