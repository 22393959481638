import { CartesianMarkerProps } from "@nivo/core";
import { ComputedDatum, CustomLayerProps, Point, Serie } from "@nivo/line";
import { ScaleLinear, ScaleTime } from "@nivo/scales";
import { area, line } from "d3-shape";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import React, { useMemo } from "react";
import colours from "styles/colours";
import { floorToHalfHour } from "utils/dateHelpers";

type LinesItemProps = Pick<CustomLayerProps, "lineGenerator"> & {
  color: string;
  points: Record<"x" | "y", number>[];
  thickness: number;
};

const HighlightLine: React.FC<LinesItemProps> = ({
  lineGenerator,
  points,
  color,
  thickness,
}) => {
  const path = useMemo(
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    () => lineGenerator(points as any), // any due to incorrect type definition in Nivo, https://github.com/plouc/nivo/issues/1604
    [lineGenerator, points]
  );

  return (
    <path
      d={path ?? undefined}
      fill="none"
      stroke={color}
      strokeWidth={thickness}
    />
  );
};

export const HighlightLines = ({
  lineGenerator,
  series: chartSeries,
}: CustomLayerProps): JSX.Element[] =>
  useMemo(
    () =>
      chartSeries
        .slice(0)
        .reverse()
        .map(({ id, data }) => (
          <HighlightLine
            key={id}
            points={data.map((d) => ({
              x: d.position.x,
              y: d.position.y - 1,
            }))}
            lineGenerator={lineGenerator}
            color={colours.white}
            thickness={1}
          />
        )),
    [chartSeries, lineGenerator]
  );

export const generateHorizontalLine = (
  amount: number,
  colour: string
): React.FC<CustomLayerProps> =>
  function horizontalLine({
    points,
    xScale: generatorXScale,
    yScale: generatorYScale,
  }): JSX.Element {
    const lineGenerator = line<Point>()
      .x((point) => (generatorXScale as ScaleTime<Date>)(point.data.x as Date))
      .y(() => (generatorYScale as ScaleLinear<number>)(amount));

    return (
      <path
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        d={lineGenerator(points) as any} // any due to incorrect type definition in Nivo, https://github.com/plouc/nivo/issues/1604
        stroke={colour}
      />
    );
  };

export const generateFixedLengthHorizontalLine = (
  amount: number,
  colour: string,
  xMin: Date,
  xMax: Date
): React.FC<CustomLayerProps> =>
  function fixedLengthHorizontalLine({
    lineGenerator,
    xScale,
    yScale,
  }): JSX.Element {
    const points = [
      {
        x: xScale(xMin),
        y: yScale(amount),
      },
      {
        x: xScale(xMax),
        y: yScale(amount),
      },
    ];
    return (
      <path
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        d={lineGenerator(points as any) ?? undefined} // any due to incorrect type definition in Nivo, https://github.com/plouc/nivo/issues/1604
        stroke={colour}
      />
    );
  };

export const VerticalLineFromXAxisToPoint: React.FC<{
  x: Date;
  y: number;
  colour: string;
  layerProps: CustomLayerProps;
}> = ({ x, y, colour, layerProps: { lineGenerator, xScale, yScale } }) => {
  const points = [
    {
      x: xScale(x),
      y: yScale(y),
    },
    {
      x: xScale(x),
      y: (yScale as ScaleLinear<number>).range()[0],
    },
  ];

  return (
    <path
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      d={lineGenerator(points as any) ?? undefined} // any due to incorrect type definition in Nivo, https://github.com/plouc/nivo/issues/1604
      stroke={colour}
    />
  );
};

const getVerticalLineMarkerAtTimeNow = (
  customDate?: Date
): CartesianMarkerProps => {
  const date = customDate ?? floorToHalfHour(new Date());
  return {
    axis: "x",
    value: date,
    lineStyle: {
      stroke: colours.mediumGrey,
    },
  };
};

export const getUnlabelledVerticalLineMarkerAtTimeNow = (
  series: Serie[],
  isChartLoaded: boolean,
  customDate?: Date
): CartesianMarkerProps[] => {
  if (series.length && isChartLoaded) {
    return [getVerticalLineMarkerAtTimeNow(customDate)];
  }

  return [];
};

export const getLabelledVerticalLineMarkerAtTimeNow = (
  series: Serie[],
  isChartLoaded: boolean,
  customDate?: Date
): CartesianMarkerProps[] => {
  if (series.length && isChartLoaded) {
    let nowMarker = getVerticalLineMarkerAtTimeNow(customDate);
    nowMarker.legend = "NOW";
    return [nowMarker];
  }

  return [];
};

export const generateFixedArea = (
  min: number,
  max: number,
  colour: string
): React.FC<CustomLayerProps> =>
  function fixedArea({
    points,
    xScale: generatorXScale,
    yScale: generatorYScale,
  }): JSX.Element {
    const lineGenerator = area<Point>()
      .x((point) => (generatorXScale as ScaleTime<Date>)(point.data.x as Date))
      .y0(() => (generatorYScale as ScaleLinear<number>)(max))
      .y1(() => (generatorYScale as ScaleLinear<number>)(min));

    return (
      <path
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        d={lineGenerator(points) as any} // any due to incorrect type definition in Nivo, https://github.com/plouc/nivo/issues/1604
        fill={colour}
      />
    );
  };

export const generateReferenceArea = (
  colour: string,
  upperSeriesId: string,
  lowerSeriesId: string
): React.FC<CustomLayerProps> =>
  function referenceArea({ points, xScale: generatorXScale }): JSX.Element {
    const highPoints = points.filter((p) => p.serieId === upperSeriesId);
    const lowPoints = points.filter((p) => p.serieId === lowerSeriesId);

    if (highPoints.length === 0 || lowPoints.length === 0) {
      return <></>;
    }

    const areaGenerator = area<Point>()
      .x((point) => (generatorXScale as ScaleTime<Date>)(point.data.x as Date))
      .y0((point) => lowPoints.find((p) => p.x === point.x)!.y)
      .y1((point) => highPoints.find((p) => p.x === point.x)!.y);

    return (
      <path
        // It doesn't matter which list of points is passed into the generator, so long as the result is only one point per x-axis value
        d={areaGenerator(highPoints)!}
        stroke={colour}
        fill={colour}
        opacity={0.5}
      />
    );
  };

const LinePathElement = (
  id: string | number,
  d: string | undefined,
  color: string | undefined,
  isDashed: boolean
): JSX.Element => (
  <path
    key={id}
    d={d}
    fill="none"
    stroke={color}
    strokeWidth={2}
    style={
      isDashed
        ? {
            strokeDasharray: "4, 4",
          }
        : undefined
    }
  />
);

export const generateDashedLine =
  (serieId: string, colour: string): React.FC<CustomLayerProps> =>
  ({ series, lineGenerator, xScale, yScale }): JSX.Element => {
    const serie = series.find((s) => s.id === serieId);
    if (serie === undefined) {
      throw new Error(
        `Could not find serie with serieId "${serieId}" to generate a dashed line.`
      );
    }

    const points = serie.data.map((d: ComputedDatum) => ({
      x: d.data.x !== null ? xScale(d.data.x as Date) : null,
      y: d.data.y !== null ? yScale(d.data.y as number) : null,
    }));

    return LinePathElement(
      serie.id,
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      lineGenerator(points as any) ?? undefined, // any due to incorrect type definition in Nivo, https://github.com/plouc/nivo/issues/1604
      colour,
      true
    );
  };

export const generateDatasetLines =
  (
    datasets: ChartDatasetModel[]
  ): ((props: CustomLayerProps) => JSX.Element[]) =>
  ({ series, lineGenerator, xScale, yScale }): JSX.Element[] =>
    // reverse before map to put earlier series on top, consistent with Nivo implementation
    series.reverse().map((serie) => {
      const dataset = datasets.find((d) => d.dataKey === serie.id);

      const isDashed = dataset?.dashed ?? false;

      const points = serie.data.map((d: ComputedDatum) => ({
        x: d.data.x !== null ? xScale(d.data.x as Date) : null,
        y: d.data.y !== null ? yScale(d.data.y as number) : null,
      }));

      return LinePathElement(
        serie.id,
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        lineGenerator(points as any) ?? undefined, // any due to incorrect type definition in Nivo, https://github.com/plouc/nivo/issues/1604
        dataset?.colour ?? undefined,
        isDashed
      );
    });
