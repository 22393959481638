import App from "App";
import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import { authContext } from "utils/authUtils";
import "styles/index.scss";
import featureFlags from "utils/featureFlags";

// Note that we shouldn't export anything from this file as it can have unusual effects
// e.g. the cookiescript modal appearing in component tests where it shouldn't

// TODO 99985: remove feature flag on Nivo tooltip jitter bug being fixed: https://github.com/plouc/nivo/issues/2161
// when false, app behaves as if it's running React 17
if (featureFlags.react18Render) {
  createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <App authContext={authContext} />
    </React.StrictMode>
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App authContext={authContext} />
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
