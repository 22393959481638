import PageMeta from "components/components/Meta/PageMeta";
import AdjustmentActionsDisbsadChartContainer from "components/dataVisualization/AdjustmentActionsDisbsad/AdjustmentActionsDisbsadChartContainer/AdjustmentActionsDisbsadChartContainer";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import { ChartFocussedDateProvider } from "contexts/ChartFocussedDateContext";
import React from "react";

const AdjustmentActionsDisbsad: React.FC = () => (
  <ChartFocussedDateProvider>
    <>
      <PageMeta title="Adjustment actions (DISBSAD)" />
      <PageHeader
        title="Adjustment actions (DISBSAD)"
        pageDescription={
          <>
            <p>
              National Grid ESO provides a set of adjustment actions, price and
              volume values for each Settlement Period.
            </p>
            <p>
              <div>Adjustment prices are shown in £/MWh.</div>
              <div>Adjustment volumes are shown in MWh.</div>
            </p>
            <p>
              The names used as the Asset Ids are provided in this{" "}
              <a
                href="https://www.elexon.co.uk/documents/operations-settlement/balancing-mechanism-units/details-on-party-id-and-asset-id-fields/"
                className="is-link"
              >
                reference document
              </a>
              .
            </p>
          </>
        }
        expanderHeading="What you need to know while using this data"
        expanderContent={
          <>
            <p>
              Disaggregated BSAD consists of a number of Balancing Services
              Adjustment Action items.
            </p>
            <p>
              When moving over the graph a modal will appear providing details
              relating to a specfic settlement period and the table below
              provides additional information.
            </p>
            <p>
              Some Balancing Services Adjustment Actions have no defined cost
              and therefore the Adjustment Cost field will be blank.
            </p>
            <p>
              The SO-Flag indicates whether or not the Action was potentially
              taken for reasons other than balancing the short-term energy
              imbalance of the Transmission System.
            </p>
            <p>
              The STOR-Flag indicates whether or not the Action was provided by
              a STOR provider.
            </p>
          </>
        }
      />
      <FullWidthChartSection>
        <AdjustmentActionsDisbsadChartContainer />
      </FullWidthChartSection>
      <AdditionalInfoSection documentationLinkSlug="balancing/nonbm/disbsad/summary" />
    </>
  </ChartFocussedDateProvider>
);

export default AdjustmentActionsDisbsad;
