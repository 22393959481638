import styled from "styled-components";
import fonts from "styles/fonts";

export const StyledTitle = styled.h2``;

export const AdditionalInfo = styled.div`
  ${StyledTitle} {
    font-size: ${fonts.mediumLarge};
    margin-bottom: 1.5rem;
  }
`;

export const StyledInfo = styled.span`
  display: block;
`;
