import DateFilterModel from "models/filterModels/dateFilterModel";

import {
  areDatesOnTheSameDay,
  endOfUkDay,
  getExclusiveDatesBetween,
  getHalfHourlyTimesBetween,
  startOfUkDay,
  toDateOnlyString,
} from "./dateHelpers";

export const normaliseDateToMinuteInterval = (
  date: Date,
  minuteInterval?: number
): Date => {
  if (!minuteInterval) {
    return date;
  }

  const milliseconds = 1000 * 60 * minuteInterval;
  const millisAwayFromInterval = date.getTime() % milliseconds;
  const millisAdjustment =
    millisAwayFromInterval > 0 ? milliseconds - millisAwayFromInterval : 0;

  const normalisedDate = new Date();
  normalisedDate.setTime(date.getTime() + millisAdjustment);
  return normalisedDate;
};

export const getSettlementDateAndPeriodFromSettlementTime = (
  settlementTime: Date
): { settlementDate: Date; settlementPeriod: number } => {
  const periodUkTime = settlementTime.toLocaleTimeString("en-GB", {
    timeZone: "Europe/London",
  });
  const periodUtcTime = settlementTime.toUTCString().slice(17, 25);
  const periodIsInBst = periodUkTime !== periodUtcTime;

  const dateInUk = settlementTime.toLocaleDateString("en-GB", {
    timeZone: "Europe/London",
  });

  const yearInUk = parseInt(dateInUk.slice(6), 10);
  const monthInUk = parseInt(dateInUk.slice(3, 5), 10) - 1;
  const dayInUk = parseInt(dateInUk.slice(0, 3), 10);

  const midnightUtcOnDateInUk = new Date(
    Date.UTC(yearInUk, monthInUk, dayInUk)
  );

  const ukTimeAtMidnightUtcOnDateInUk =
    midnightUtcOnDateInUk.toLocaleTimeString("en-GB", {
      timeZone: "Europe/London",
    });

  const startOfDayIsInBst = ukTimeAtMidnightUtcOnDateInUk !== "00:00:00";

  const hasShortDayAdjustment = periodIsInBst && !startOfDayIsInBst;
  const hasLongDayAdjustment = !periodIsInBst && startOfDayIsInBst;

  let clockChangeOffset = 0;

  if (hasShortDayAdjustment) {
    clockChangeOffset = -1;
  }

  if (hasLongDayAdjustment) {
    clockChangeOffset = 1;
  }

  const ukTimeHours = parseInt(periodUkTime.slice(0, 2), 10);
  const ukTimeMinutes = parseInt(periodUkTime.slice(3, 5), 10);

  return {
    settlementDate: midnightUtcOnDateInUk,
    settlementPeriod:
      (ukTimeHours + clockChangeOffset) * 2 +
      Math.round(ukTimeMinutes / 30) +
      1,
  };
};

export const getSettlementPeriodFromSettlementTime = (
  settlementTime: Date
): number =>
  getSettlementDateAndPeriodFromSettlementTime(settlementTime).settlementPeriod;

/**
 * Finds the start year of the most recent Triad season for the given date. If no date is given then the current date is used.
 *
 * The Triad season runs from November to February, so
 * * if the given date is in January to October (inclusive) then the returned year will be the year before, and
 * * if the given date is in November or December then the returned year will be the same as the given year.
 *
 * @example getMostRecentTriadSeasonYear(new Date("October 31, 2020")) // returns 2019
 * @example getMostRecentTriadSeasonYear(new Date("November 01, 2020")) // return 2020
 * @example getMostRecentTriadSeasonYear(new Date("January 01, 2021")) // returns 2020
 */
export const getMostRecentTriadSeasonYear = (asOf?: Date): number => {
  const date = asOf ?? new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // The new Triad season starts in November
  return month >= 11 ? year : year - 1;
};

const getFullDaysBetweenDates = (startDate: Date, endDate: Date): string[] => {
  return getExclusiveDatesBetween(startDate, endDate).map((date) =>
    toDateOnlyString(date)
  );
};

const settlementPeriodToPathRequest = (period: {
  settlementDate: Date;
  settlementPeriod: number;
}): string =>
  `${toDateOnlyString(period.settlementDate)}/${period.settlementPeriod}`;

export const getSettlementDateAndPeriodRequestsBetweenTimes = (
  startTime: Date,
  endTime: Date
): string[] => {
  const halfHourlyTimes = getHalfHourlyTimesBetween(startTime, endTime);
  const settlementPeriods = halfHourlyTimes.map((time) =>
    getSettlementDateAndPeriodFromSettlementTime(time)
  );
  return settlementPeriods.map((period) =>
    settlementPeriodToPathRequest(period)
  );
};

export const getPathsFromDateFilter = (
  dateFilter: DateFilterModel
): string[] => {
  const startDate = dateFilter.startDate;
  const latestTimeOfStartDay = endOfUkDay(startDate);
  const endDate = dateFilter.endDate;
  const earliestTimeOfEndDay = startOfUkDay(endDate);

  if (areDatesOnTheSameDay(startDate, endDate)) {
    return getSettlementDateAndPeriodRequestsBetweenTimes(startDate, endDate);
  }

  const startDatePerSettlementPeriodRequests =
    getSettlementDateAndPeriodRequestsBetweenTimes(
      startDate,
      latestTimeOfStartDay
    );

  const fullDayRequests = getFullDaysBetweenDates(startDate, endDate);

  const endDatePerSettlementPeriodRequests =
    getSettlementDateAndPeriodRequestsBetweenTimes(
      earliestTimeOfEndDay,
      endDate
    );

  return [
    ...startDatePerSettlementPeriodRequests,
    ...fullDayRequests,
    ...endDatePerSettlementPeriodRequests,
  ];
};
