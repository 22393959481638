import { getRemitTableJsonData } from "api/remit/remit";
import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import PageMeta from "components/components/Meta/PageMeta";
import RemitFiltersForm from "components/components/Remit/RemitFiltersForm/RemitFiltersForm";
import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import { ChartFooter } from "components/dataVisualization/chartComponents/style";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import RemitLandingPageDataExporter from "components/dataVisualization/remit/RemitLandingPageDataExporter";
import RemitLandingPageTable from "components/dataVisualization/remit/RemitLandingPageTable";
import { maxRemitTableRows, title } from "components/remit/remitConfig";
import {
  getRemitFiltersFromUrlParams,
  mapToRemitRow,
  mridFilter,
  sortRemitData,
} from "components/remit/remitLandingPageHelpers";
import useRequest from "hooks/useRequest";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const Remit: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { request, data, status } = useRequest(getRemitTableJsonData);
  const [mridValue, setMridValue] = useState<string | null>(null);
  const [endpoint, setEndpoint] = useState<string>(
    getRemitFiltersFromUrlParams(searchParams).endpoint
  );

  const fetchData = useCallback(() => {
    const filters = getRemitFiltersFromUrlParams(searchParams);
    request(filters);
    setMridValue(filters.messageID);
    setEndpoint(filters.endpoint);
  }, [request, searchParams]);

  useEffect(() => fetchData(), [fetchData]);

  const mappedData = data ? mapToRemitRow(data.data) : [];
  const filteredData = mappedData.filter((r) => mridFilter(r, mridValue));
  const sortedData = sortRemitData(filteredData, endpoint);

  const dataIsEmpty = filteredData.length == 0;
  const tooMuchData = filteredData.length > maxRemitTableRows;
  const shouldShowExportButton = !dataIsEmpty && !tooMuchData;

  return (
    <>
      <PageMeta title={title} />
      <PageMeta title="REMIT" />
      <PageHeader
        title="REMIT"
        pageDescription={
          <>
            <p>
              REMIT (regulation on wholesale energy markets integrity and
              transparency) data is the ‘inside information’ in respect of
              businesses or facilities that market participants own or control
              (or are operationally responsible for) which they are obliged to
              publish before they can trade on it.
            </p>
          </>
        }
        expanderHeading="What you need to know while using this data"
        expanderContent={
          <>
            <p>
              This page displays the latest REMIT messages that have have been
              submitted.
            </p>
            <p>
              Use the Timeframe and/or Additional filters to filter your search.
              If looking for a specific message, enter the Message Group ID. You
              can view up to 1 month of data at a time. If filtering to a
              specific Asset or Participant, you can extend this to 1 year.
            </p>
            <p>
              Further details regarding submitted messages can be viewed by
              clicking on a specific message shown in the table.
            </p>
          </>
        }
      ></PageHeader>
      <FullWidthSection className="is-grey-section">
        <RemitFiltersForm
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </FullWidthSection>
      <FullWidthSection className="is-fullwidth">
        <TableWithErrorHandling
          data={sortedData}
          dataFetchStatus={status}
          rerequestData={fetchData}
          maxRows={maxRemitTableRows}
        >
          <RemitLandingPageTable data={sortedData} />
        </TableWithErrorHandling>
      </FullWidthSection>
      {shouldShowExportButton && (
        <ChartFooter rightAligned>
          <RemitLandingPageDataExporter searchParams={searchParams} />
        </ChartFooter>
      )}
      <FullWidthSection>
        This information has been provided by market participants to comply with
        Article 4 of REGULATION (EU) No 1227/2011 OF THE EUROPEAN PARLIAMENT AND
        OF THE COUNCIL of 25 October 2011 on wholesale energy market integrity
        and transparency.
      </FullWidthSection>
      <AdditionalInfoSection documentationLinkSlug="REMIT" />
    </>
  );
};

export default Remit;
