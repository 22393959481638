import PageMeta from "components/components/Meta/PageMeta";
import AdjustmentDataNetbsadChartContainer from "components/dataVisualization/AdjustmentDataNetbsad/AdjustmentDataNetbsadChartContainer/AdjustmentDataNetbsadChartContainer";
import AdjustmentDataNetbsadSummaryTile from "components/dataVisualization/AdjustmentDataNetbsad/AdjustmentDataNetbsadSummaryTile/AdjustmentDataNetbsadSummaryTile";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import { ChartFocussedDateProvider } from "contexts/ChartFocussedDateContext";
import React from "react";

const AdjustmentDataNetbsad: React.FC = () => (
  <ChartFocussedDateProvider>
    <>
      <PageMeta title="Adjustment data (NETBSAD)" />
      <PageHeader
        title="Adjustment data (NETBSAD)"
        pageDescription={
          <>
            <p>
              National Grid ESO provides a set of adjustment buy/sell price
              values for each Settlement Period, shown in £/MWh.
            </p>
          </>
        }
        expanderHeading="What you need to know while using this data"
        expanderContent={
          <>
            <p>
              This page displays the latest NETBSAD data received by the BMRA
              which may differ from those displayed in the System Prices web
              page. The NETBSAD values displayed in the System Prices web page
              are the actual values used in the calculation of those prices and
              may have been superseded by later values received by the BMRA.
            </p>
          </>
        }
      >
        <AdjustmentDataNetbsadSummaryTile />
      </PageHeader>
      <FullWidthChartSection>
        <AdjustmentDataNetbsadChartContainer />
      </FullWidthChartSection>
      <AdditionalInfoSection documentationLinkSlug="balancing/nonbm/netbsad" />
    </>
  </ChartFocussedDateProvider>
);

export default AdjustmentDataNetbsad;
