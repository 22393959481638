import Dropdown from "components/components/Dropdown/Dropdown";
import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import PageMeta from "components/components/Meta/PageMeta";
import SettlementPeriodSelector from "components/components/SettlementPeriodSelector/SettlementPeriodSelector";
import Tabs, {
  DetailedSystemPricesTabEnum,
  DetailedSystemPricesTabName,
  TabDetails,
  WidthType,
} from "components/components/Tabs/Tabs/Tabs";
import CheckboxDataExporter from "components/dataVisualization/CheckboxDataExporter/CheckboxDataExporter";
import DetailedSystemPricesSummary from "components/dataVisualization/DetailedSystemPrices/DetailedSystemPricesSummary/DetailedSystemPricesSummary";
import BuyStackTab from "components/dataVisualization/DetailedSystemPrices/DetailedSystemPricesTabs/BuyStackTab/BuyStackTab";
import SellStackTab from "components/dataVisualization/DetailedSystemPrices/DetailedSystemPricesTabs/SellStackTab/SellStackTab";
import useSystemPricesConsolidatedData from "components/dataVisualization/DetailedSystemPrices/SystemPricesHooks/useSystemPricesConsolidatedData";
import {
  datasets,
  transformDetailedSystemPricesDownloadCallbacks,
} from "components/dataVisualization/DetailedSystemPrices/utils";
import { ChartFooter } from "components/dataVisualization/chartComponents/style";
import AdditionalInfoSection, {
  ExternalDataSource,
} from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import DateFilterModel from "models/filterModels/dateFilterModel";
import {
  ActionTypeFilter,
  StyledDiv,
  WideTabContainer,
} from "pages/DetailedSystemPrices/style";
import { ActionType } from "pages/DetailedSystemPrices/utils";
import React, { useEffect, useState } from "react";
import { addMinsToDate, floorToHalfHour } from "utils/dateHelpers";

const DetailedSystemPrices: React.FC = () => {
  const defaultDate = addMinsToDate(
    floorToHalfHour(addMinsToDate(new Date(), -5)),
    60
  );
  const [selectedDate, setSelectedDate] = useState<Date>(defaultDate);
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(selectedDate, addMinsToDate(selectedDate, 30))
  );

  const request = useSystemPricesConsolidatedData(selectedDate);
  const [activeTabTitle, setActiveTabTitle] =
    useState<DetailedSystemPricesTabName>("Buy stack");

  useEffect(() => {
    setDateFilter(
      new DateFilterModel(selectedDate, addMinsToDate(selectedDate, 30))
    );
  }, [selectedDate]);

  const [actionType, setActionType] = useState<ActionType>(ActionType.All);

  const tabs: TabDetails<DetailedSystemPricesTabName>[] = [
    {
      title: DetailedSystemPricesTabEnum.BuyStack,
      children: <BuyStackTab request={request} actionType={actionType} />,
    },
    {
      title: DetailedSystemPricesTabEnum.SellStack,
      children: <SellStackTab request={request} actionType={actionType} />,
    },
  ];

  return (
    <>
      <PageMeta title="Detailed system prices" />
      <PageHeader
        title="Detailed system prices"
        pageDescription={
          <>
            <p>
              The indicative price calculation is published 15 minutes after the
              effective Settlement Period based on parameters available at the
              time the calculations were run. It shows a detailed breakdown of
              the indicative System Price calculations Stack either as Buy or
              Sell Stacks listing all accepted actions included in the System
              Price calculations.
            </p>
            <p>
              This includes the System operators actions (Bid-Offer Acceptance
              Volumes and Balancing Service Adjustment Actions) showing the
              initial data along with details of how the indicative price
              calculation processing affects each item&apos;s volume and price.
            </p>
          </>
        }
        expanderHeading="What you need to know while using this data"
        expanderContent={
          <p>
            The calculations are refreshed at D+1, i.e. the following day, to
            account for late actions or changes made by the System Operator
            showing a more accurate view of the price calculations.
          </p>
        }
      />
      <FullWidthSection className="is-grey-section">
        <StyledDiv className="container px-6">
          <h2>Time frame</h2>
          <SettlementPeriodSelector
            selectedDate={selectedDate}
            handleChangeToDateSelection={setSelectedDate}
            maxDate={defaultDate}
          />
          <DetailedSystemPricesSummary request={request} />
          <ActionTypeFilter data-test-id="action-type-filter">
            <label>Action Type</label>
            <Dropdown
              values={Object.values(ActionType)}
              selectedValue={actionType}
              onChange={(newValue: string): void =>
                setActionType(newValue as ActionType)
              }
              ariaLabel={"Action type dropdown"}
            />
          </ActionTypeFilter>
        </StyledDiv>
      </FullWidthSection>
      <WideTabContainer>
        <Tabs
          widthTypeTab={WidthType.NotWide}
          widthTypeTabBody={WidthType.Wide}
          tabs={tabs}
          showDefault={false}
          activeTabTitle={activeTabTitle}
          setActiveTabTitle={setActiveTabTitle}
        />
        <ChartFooter rightAligned>
          <CheckboxDataExporter
            dateFilter={dateFilter}
            datasets={datasets}
            transformToDownloadCallbacks={(
              datasetsToDownload: string[]
            ): DownloadCallback[] =>
              transformDetailedSystemPricesDownloadCallbacks(
                datasetsToDownload,
                dateFilter
              )
            }
          />
        </ChartFooter>
      </WideTabContainer>
      <AdditionalInfoSection
        documentationLinkSlug="balancing/settlement/summary/{settlementDate}/{settlementPeriod}"
        source={ExternalDataSource.Elexon}
      />
    </>
  );
};

export default DetailedSystemPrices;
