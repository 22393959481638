import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import { Spacer } from "components/dataVisualization/dataPageComponents/FullWidthChartSection/style";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import SoSoTradePricesTableContainer from "components/dataVisualization/soSoTradePrices/SoSoTradePricesTableContainer/SoSoTradePricesTableContainer";
import React from "react";

const SoSoTradePrices: React.FC = () => (
  <>
    <PageMeta title="SO-SO trade prices" />
    <PageHeader
      title="SO-SO trade prices"
      pageDescription={
        <>
          <p>
            System operator to system operator (SO-SO) services are provided
            mutually with other transmission system operators connected to
            Britain&apos;s transmission system via interconnectors.
          </p>
          <p>
            Britain&apos;s electricity market connects to continental Europe via
            interconnectors, which allow electricity to be traded between these
            markets.
          </p>
          <p>
            This trading determines the direction of electricity flow. There may
            be instances when the system operator needs to adjust these
            interconnector flows closer to real time, via system operator to
            system operator (SO to SO) services.
          </p>
        </>
      }
    />
    <Spacer className={"full-width"} />
    <SoSoTradePricesTableContainer />
    <AdditionalInfoSection documentationLinkSlug="SOSO/prices" />
  </>
);

export default SoSoTradePrices;
